import Graph from "components/hub/Graph";
import TotalRewardsGraph from "components/hub/TotalRewardsGraph";
import { ethers } from "ethers";
import {
  CollectionDailyStats,
  CollectionStats,
  GetFloorPrice,
  TopOAHolders,
  TotalDailyRewards,
} from "graphql/api";
import { ORBITAL_APES_CONTRACT } from "helpers/payTokens";
import { useEffect, useState } from "react";
import { nFormatter } from "../formatNumber";
import { ACRE_REWARDS, EVD_REWARDS, TORI_REWARDS } from "./airdropRewards";
import Airdrops from "./Airdrops";
import TopUsers from "./TopUsers";

export default function OrbitalApesStats({totalRewards}) {


  const [floorPrice, setFloorPrice] = useState(0);
  const [collectionStats, setCollectionStats] = useState({});
  const [topHolders, setTopHolders] = useState([]);
  const [totalDailyRewards, setTotalDailyRewards] = useState([]);
  const [oaDailyStats, setOADailyStats] = useState([]);

  useEffect(() => {
    CollectionDailyStats().then((cds) => {
      setOADailyStats(cds);
    });
  }, []);

  useEffect(() => {
    GetFloorPrice(ORBITAL_APES_CONTRACT).then((fp) => {
      setFloorPrice(ethers.utils.formatEther(fp ?? 0));
    });
    CollectionStats(ORBITAL_APES_CONTRACT).then((cs) => {
      setCollectionStats(cs ?? {});
    });
    TopOAHolders().then((th) => {
      setTopHolders(th);
    });
    TotalDailyRewards().then((tdr) => {
      setTotalDailyRewards(tdr);
    });

  }, []);

  function Header({ floorPrice, totalRewards }) {
    const total = () => {
      let sum = 0;
      totalRewards.forEach((r) => {
        if (r.type !== "ALL") {
          const _amount = parseFloat(
            ethers.utils.formatEther(r.amountDeposited ?? 0)
          );
          sum += _amount;
        }
      });

      return (sum + TORI_REWARDS + EVD_REWARDS+ACRE_REWARDS).toFixed(2);
    };

    return (
      <div className="flex flex-wrap gap-5">
        <div className="flex w-44 flex-col border border-purple rounded-md px-4 py-2.5 bg-purple bg-opacity-5">
          <span className="text-white">Floor price</span>
          <span className="text-white font-semibold text-xl">
            {floorPrice} EVMOS
          </span>
        </div>
        <div className="flex w-44 flex-col border border-purple rounded-md px-4 py-2.5 bg-purple bg-opacity-5">
          <span className="text-white">Supply listed</span>
          <span className="text-white font-semibold text-xl">
            {((parseInt(collectionStats?.listingsCount ?? 0) / 10000) * 100).toFixed(2)}%
          </span>
        </div>
        <div className="flex w-44 flex-col border border-purple rounded-md px-4 py-2.5 bg-purple bg-opacity-5">
          <span className="text-white">Owners</span>
          <span className="text-white font-semibold text-xl">
            {collectionStats?.numOwners ?? 0}
          </span>
        </div>
        <div className="flex w-44 flex-col border border-purple rounded-md px-4 py-2.5 bg-purple bg-opacity-5">
          <span className="text-white">Resale Volume</span>
          <span className="text-white font-semibold text-xl">
            {nFormatter(
              ethers.utils.formatEther(collectionStats?.sellsVolume ?? 0)
            )}{" "}
            EVMOS
          </span>
        </div>
        <div className="flex w-44 flex-col border border-purple rounded-md px-4 py-2.5 bg-purple bg-opacity-5">
          <span className="text-white">Rewards Earned</span>
          <span className="text-white font-semibold text-xl">
            {nFormatter(total(), 2)}
          </span>
        </div>
      </div>
    );
  }

  function RewardsReceived({ totalDailyRewards }) {
    return (
      <div className="flex flex-1 gap-2 flex-col">
        <span className="text-white text-xl font-medium">
          Total Rewards Earned
        </span>
        <div className="h-56 border p-2 border-purple rounded-md">
          <TotalRewardsGraph
            height={240}
            data={totalDailyRewards.map(r => {
              return {
                ...r,
                "Validator + dApps": r.amountDeposited
              }
            })}
            aggKey={"Validator + dApps"}
            title={"Total EVMOS Rewards"}
          />
        </div>
      </div>
    );
  }

  function Owners({ oaDailyStats }) {
    return (
      <div className="flex flex-1 gap-2 flex-col">
        <span className="text-white text-xl font-medium">Owners</span>
        <div className="h-56 border p-2 border-purple rounded-md">
          <Graph
            height={190}
            data={oaDailyStats.map((ds) => {
              return { ...ds, owners: ds.numOwners };
            })}
            aggKey={"owners"}
            title={"Owners"}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-10">
      <span className="text-white font-semibold text-2xl">Orbital Apes</span>
      <Header totalRewards={totalRewards} floorPrice={floorPrice} />
      <Airdrops/>
      <div className="flex flex-col mdd:flex-row pt-5 flex-1 gap-5">
        <TopUsers
          selectKey={"owner"}
          dataKey={"amount"}
          topHolders={topHolders}
          title={"Top Holders"}
        />

        <div className="flex flex-1 gap-4 flex-col">
          <RewardsReceived totalDailyRewards={totalDailyRewards} />
          <Owners oaDailyStats={oaDailyStats} />
        </div>
      </div>
    </div>
  );
}
