import { getProposalLabel } from "helpers/proposals";
import { fetchData } from "lambda/lambda";
import moment from "moment";
import { useEffect, useState } from "react";
import cs from "classnames"
import proposalsVotes from "./proposals.json"

export default function ProposalRow({ proposal }) {
  const [OAVote, setOAVote] = useState();

  useEffect(() => {
    if (proposalsVotes[proposal?.id]) {
      return setOAVote(proposalsVotes[proposal?.id])
    }
    fetchData(
      `https://rest.bd.evmos.org:1317/cosmos/gov/v1/proposals/${proposal.id}/votes/evmos1mx9nqk5agvlsvt2yc8259nwztmxq7zjq50mxkp`
    ).then((vote) => {
      if (vote.vote) {
        if (vote.vote.options.length > 0) {
          if (vote.vote.options[0]?.option?.includes("YES")) {
            setOAVote("Yes");
          } else if (vote.vote.options[0]?.option?.includes("NO")) {
            setOAVote("No");
          } else if (vote.vote.options[0]?.option?.includes("ABSTAIN")) {
            setOAVote("Abstain");
          }
          else {
            setOAVote("Waiting for vote")
          }
        }
      }
    });
  }, [proposal.id]);

  return (
    <div className="rounded text-white flex-1 bg-dark border-2 border-darkGray  p-4 gap-5 flex flex-col shadow">
      <div className="flex gap-2.5 flex-col p-2">
        <div className="flex justify-between">
          <span className="font-semibold">#{proposal.id}</span>
          {getProposalLabel(proposal)}
        </div>
        <div className="flex justify-between">
          <span>
            {proposal?.messages?.length > 0
              ? proposal?.messages[0]?.content?.title
              : ""}
          </span>
        </div>
        <div className="flex pt-3 gap-20">
          <div className="flex gap-1 flex-col">
            <span className="font-semibold">Starts</span>
            <span>
              {` ${moment(
                new Date(proposal?.voting_start_time ?? 0).toISOString()
              ).format("MMMM Do YYYY, h:mm a")}`}
            </span>
          </div>
          <div className="flex gap-1 flex-col">
            <span className="font-semibold">Ends</span>
            <span>
              {` ${moment(
                new Date(proposal?.voting_end_time ?? 0).toISOString()
              ).format("MMMM Do YYYY, h:mm a")}`}
            </span>
          </div>
          <div className="flex gap-1 flex-col">
            <span className="font-semibold">OA's Vote</span>
            <span className={cs("rounded-full px-2.5  justify-center text-center font-medium   bg-opacity-20", {
              "bg-green": OAVote === "Yes",
              "bg-red": OAVote === "No",
              "bg-purple": OAVote === "Abstain"
            })}>
              {OAVote}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
