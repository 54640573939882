import Nuxian from "../../../assets/hub/nuxian.png";
import OA from "../../../assets/placeholders/logo.png";

export default function ActiveValidators() {
    return (
      <div className="mdd:w-6/12 w-full gap-3 flex flex-col">
        <span className="text-white font-semibold text-2xl">
          Active Validators
        </span>
        <div className="rounded  bg-dark border-2 border-darkGray pt-2 p-4 gap-5 flex flex-col shadow">
          <div className="flex gap-3 flex-col text-lg">
            <span className="font-medium text-purple">Evmos</span>
            <div className="flex flex-col gap-2.5">
              <div className="flex border rounded px-2 py-0.5 border-darkGray2 bg-purple bg-opacity-10">
                <div className="flex justify-between flex-1 items-center">
                  <div className="flex items-center gap-2 text-base">
                    <img src={OA} alt="OA" className="w-5 h-5 rounded" />
                    <span className="w-32">Orbital Apes</span>
                    <span> 50% rewards to holders </span>
                  </div>

                  <div className="rounded-full justify-self-end animate-pulse bg-green h-2 w-2" />
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-3 flex-col text-lg">
            <span className="font-medium text-purple">Teritori</span>
            <div className="flex flex-col gap-2.5">
              <div className="flex border rounded px-2 py-0.5 border-darkGray2 bg-purple bg-opacity-10">
                <div className="flex justify-between flex-1 items-center">
                  <div className="flex items-center gap-2 text-base">
                    <img src={OA} alt="OA" className="w-5 h-5 rounded" />
                    <span className="w-32">Orbital Apes</span>
                    <span> 20% rewards to holders </span>
                  </div>

                  <div className="rounded-full justify-self-end animate-pulse bg-green h-2 w-2" />
                </div>
              </div>
              <div className="flex border rounded px-2 py-0.5 border-darkGray2 bg-purple bg-opacity-10">
                <div className="flex justify-between flex-1 items-center">
                  <div className="flex items-center gap-2 text-base">
                    <img
                      src={Nuxian}
                      alt="nuxian"
                      className="w-5 h-5 rounded"
                    />
                    <span className="w-32">Nuxian Labs</span>
                    <span> 20% rewards to holders </span>
                  </div>

                  <div className="rounded-full justify-self-end animate-pulse bg-green h-2 w-2" />
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-3 flex-col text-lg">
            <span className="font-medium text-purple">Acrechain</span>
            <div className="flex flex-col gap-2.5">
              <div className="flex border rounded px-2 py-0.5 border-darkGray2 bg-purple bg-opacity-10">
                <div className="flex justify-between flex-1 items-center">
                  <div className="flex items-center gap-2 text-base">
                    <img src={OA} alt="OA" className="w-5 h-5 rounded" />
                    <span className="w-32">Orbital Apes</span>
                    <span> 20% rewards to holders </span>
                  </div>

                  <div className="rounded-full justify-self-end animate-pulse bg-yellow h-2 w-2" />
                </div>
              </div>
              <div className="flex border rounded px-2 py-0.5 border-darkGray2 bg-purple bg-opacity-10">
                <div className="flex justify-between flex-1 items-center">
                  <div className="flex items-center gap-2 text-base">
                    <img
                      src={Nuxian}
                      alt="nuxian"
                      className="w-5 h-5 rounded"
                    />
                    <span className="w-32">Nuxian Labs</span>
                    <span> 20% rewards to holders </span>
                  </div>

                  <div className="rounded-full justify-self-end animate-pulse bg-yellow h-2 w-2" />
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-3 flex-col text-lg">
            <span className="font-medium text-purple">Rebus</span>
            <div className="flex flex-col gap-2.5">
              <div className="flex border rounded px-2 py-0.5 border-darkGray2 bg-purple bg-opacity-10">
                <div className="flex justify-between flex-1 items-center">
                  <div className="flex items-center gap-2 text-base">
                    <img
                      src={Nuxian}
                      alt="nuxian"
                      className="w-5 h-5 rounded"
                    />
                    <span className="w-32">Nuxian Labs</span>
                    <span> 20% rewards to holders </span>
                  </div>

                  <div className="rounded-full justify-self-end animate-pulse bg-yellow h-2 w-2" />
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-3 flex-col text-lg">
            <span className="font-medium text-purple">Nomic</span>
            <div className="flex flex-col gap-2.5">
              <div className="flex border rounded px-2 py-0.5 border-darkGray2 bg-purple bg-opacity-10">
                <div className="flex justify-between flex-1 items-center">
                  <div className="flex items-center gap-2 text-base">
                    <img
                      src={Nuxian}
                      alt="nuxian"
                      className="w-5 h-5 rounded"
                    />
                    <span className="w-32">Nuxian Labs</span>
                    <span> 20% rewards to holders </span>
                  </div>

                  <div className="rounded-full justify-self-end animate-pulse bg-yellow h-2 w-2" />
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-3 flex-col text-lg">
            <span className="font-medium text-purple">Osmo</span>
            <div className="flex flex-col gap-2.5">
              <div className="flex border rounded px-2 py-0.5 border-darkGray2 bg-purple bg-opacity-10">
                <div className="flex justify-between flex-1 items-center">
                  <div className="flex items-center gap-2 text-base">
                    <img
                      src={Nuxian}
                      alt="nuxian"
                      className="w-5 h-5 rounded"
                    />
                    <span className="w-32">Nuxian Labs</span>
                    <span> 20% rewards to holders </span>
                  </div>

                  <div className="rounded-full justify-self-end animate-pulse bg-red h-2 w-2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }