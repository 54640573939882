import { nFormatter } from "../formatNumber";
import { ACRE_REWARDS, EVD_REWARDS, TORI_REWARDS } from "./airdropRewards";
import Tori from "../../../assets/tokens/tori.svg";
import Acre from "../../../assets/tokens/Acre.svg";
import { useState } from "react";

export default function Airdrops() {
  function EVMOSDomains() {
    const [isOpen, setIsOpen] = useState(false);
    return (
      <div className="flex border w-full rounded px-2 py-0.5 border-darkGray2 bg-purple bg-opacity-10">
        <div className="flex w-full text-white gap-2 flex-col">
          <div className="flex w-full items-center gap-2 text-base">
            <img
              src={
                "https://assets.coingecko.com/coins/images/27785/small/evmos-domains.png"
              }
              alt="OA"
              className="w-5 h-5 rounded"
            />
            <span className="w-4/12">EVMOS Domains</span>
            <span className="w-3/12"> 15 $EVD/OA </span>
            <span className="w-3/12"> 150,000 $EVD Total</span>
            <span className="w-3/12 text-right">
              {nFormatter(EVD_REWARDS, 2)} EVMOS *
            </span>
          </div>
          {isOpen && (
            <div className="flex text-sm flex-col">
              <div className="flex w-full p-3 py-0 pr-0 font-light justify-between">
                <span className="w-4/12 pl-4">Airdrop 1: OA Holders</span>
                <span className="w-3/12 pl-3"> 10 $EVD/OA </span>
                <span className="w-3/12 pl-3"> 100,000 $EVD Total</span>
                <span className="w-3/12 text-right">{"2.43K"} EVMOS</span>
              </div>
              <div className="flex w-full p-3 pr-0 font-light justify-between">
                <span className="w-4/12 pl-4">
                  Airdrop 1: OA & Domain Holders
                </span>
                <span className="w-3/12 pl-3"> 5 $EVD/OA </span>
                <span className="w-3/12 pl-3"> 50,000 $EVD Total</span>
                <span className="w-3/12 text-right">{"1.72K"} EVMOS</span>
              </div>
              <div className="flex w-full p-3 py-0 pr-0 font-light justify-between">
                <span className="w-4/12 pl-4">Airdrop 2: OA Holders</span>
                <span className="w-3/12 pl-3"> 6 $EVD/OA </span>
                <span className="w-3/12 pl-3"> 60,000 $EVD Total</span>
                <span className="w-3/12 text-right">{"---"}</span>
              </div>
              <div className="flex w-full p-3 pr-0 font-light justify-between">
                <span className="w-4/12 pl-4">
                  Airdrop 2: OA & Domain Holders
                </span>
                <span className="w-3/12 pl-3"> 3 $EVD/OA </span>
                <span className="w-3/12 pl-3"> 30,000 $EVD Total</span>
                <span className="w-3/12 text-right">{"---"}</span>
              </div>
            </div>
          )}
          <button
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className="text-purple"
          >
            {isOpen ? "Hide details" : "See details"}
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className="flex ">
      <div className="flex flex-1 max-w-5xl flex-col gap-2">
        <span className="text-white text-xl font-medium">Airdrops</span>
        <div className="flex gap-2 p-3 border border-purple rounded-md flex-col">
          <EVMOSDomains />
          <div className="flex border rounded px-2 py-0.5 border-darkGray2 bg-purple bg-opacity-10">
            <div className="flex text-white flex-1 items-center gap-2 text-base">
              <img src={Tori} alt="OA" className="w-5 h-5 rounded" />
              <div className="flex w-full justify-between">
                <span className="w-4/12">Teritori</span>
                <span className="w-3/12"> 300 $TORI/OA </span>
                <span className="w-3/12"> 3,000,000 $TORI Total</span>
                <span className="w-3/12 text-right">
                  {nFormatter(TORI_REWARDS, 2)} EVMOS *
                </span>
              </div>
            </div>
          </div>
          <div className="flex border rounded px-2 py-0.5 border-darkGray2 bg-purple bg-opacity-10">
            <div className="flex text-white flex-1 items-center gap-2 text-base">
              <img src={Acre} alt="OA" className="w-5 h-5 rounded" />
              <div className="flex w-full justify-between">
                <span className="w-4/12">Acrechain</span>
                <span className="w-3/12"> 100 $ACRE/OA </span>
                <span className="w-3/12"> 1,000,000 $ACRE Total</span>
                <span className="w-3/12 text-right">
                  {nFormatter(ACRE_REWARDS, 2)} EVMOS *
                </span>
              </div>
            </div>
          </div>
          <span className="font-light text-white pt-3 text-xs">
            * EVMOS amount for each airdrop is calculated using the average price of the airdropped token & EVMOS for the 1st months after the airdrop claim start date
          </span>
        </div>
      </div>
    </div>
  );
}
