import ApeProfile from "../../assets/hub/apeProfile.svg";
import Orbit from "../../assets/branding/Logo.svg";
import Rumble from "../../assets/hub/Logo.svg";
import OwnerDiscord from "components/hub/OwnerDiscord";
import { useEffect, useRef, useState } from "react";
import { useEVM } from "hooks/EVMhook";
import { getDelegations } from "./helpers";
import { ethers } from "ethers";
import { useTokenData } from "helpers/tokens";
import { useParams } from "react-router-dom";
import { isSameAddress } from "helpers/address";
import {
  OAMintInfo,
  RumbleUserInfo,
  UserOrbitStats,
  UserRewardsSummary,
  UserTokens,
} from "graphql/api";
import {
  DEFAULT_ERC20,
  ORBITAL_APES_CONTRACT,
  ZERO_ADDRESS,
} from "helpers/payTokens";
import { nFormatter } from "./formatNumber";
import Identicon from "components/Identicon";
import Address from "components/hub/AddressHelper";

export default function Profile() {
  const { useraddress } = useParams();

  const [delegations, setDelegations] = useState([]);
  const [rumbleInfo, setRumbleInfo] = useState([]);
  const [rewardsClaimed, setRewardsClaimed] = useState(0);
  const [orbitInfo, setOrbitInfo] = useState([]);
  const [ownedOAs, setOwnedOAs] = useState([]);
  const [mintInfo, setMintInfo] = useState({});

  const firstUpdate = useRef(true);

  const stakedBalance = ethers.utils.formatEther(
    delegations?.find(
      (e) =>
        e.delegation.validator_address ===
        "evmosvaloper1mx9nqk5agvlsvt2yc8259nwztmxq7zjqep5khu"
    )?.balance?.amount ?? 0
  );

  const { address } = useEVM();
  const privateProfile = isSameAddress(useraddress, address);

  useEffect(() => {
    if (firstUpdate.current === false || !useraddress) {
      return;
    }
    firstUpdate.current = false;

    UserTokens(useraddress).then((ownedCount) => {
      setOwnedOAs(ownedCount?.map((o) => o.tokenId));
    });
    OAMintInfo(useraddress).then((mi) => {
      setMintInfo(mi);
    });
  }, [useraddress]);

  useEffect(() => {
    if (!useraddress) {
      return;
    }
    getDelegations(useraddress).then((d) => setDelegations(d));
    RumbleUserInfo(useraddress).then((i) => setRumbleInfo(i));
    UserRewardsSummary(useraddress).then((rs) => {
      let sum = 0;
      rs.forEach((claimRecord) => {
        if (
          isSameAddress(claimRecord.payToken, ZERO_ADDRESS) ||
          isSameAddress(claimRecord.payToken, DEFAULT_ERC20)
        ) {
          const _amount = parseFloat(
            ethers.utils.formatEther(claimRecord.amountClaimed ?? 0)
          );
          sum += _amount;
        }
      });
      setRewardsClaimed(sum);
    });
    UserOrbitStats(useraddress).then((os) => {
      setOrbitInfo(os);
    });
  }, [useraddress]);

  function EcosystemStats({ rumbleInfo, orbitInfo }) {
    return (
      <div className="flex gap-3 flex-col">
        <span className="text-white font-semibold text-2xl">
          Ecosystem Stats
        </span>
        <div className="rounded  flex-1  gap-6 flex flex-wrap shadow">
          <OrbitStats orbitInfo={orbitInfo} />
          <RumbleStats rumbleInfo={rumbleInfo} />
        </div>
      </div>
    );
  }

  function OrbitStats({ orbitInfo = [] }) {
    const aggregatedInfo = () => {
      let transactionSum = 0;
      let volumeSum = 0;
      orbitInfo.forEach((oi) => {
        transactionSum += oi.transactionCount;
        const _amount = parseFloat(ethers.utils.formatEther(oi.volume ?? 0));
        volumeSum += _amount;
      });
      return {
        transactionSum,
        volumeSum: nFormatter(volumeSum, 2),
      };
    };

    return (
      <div className="flex p-3 border w-full rounded border-darkGray2 bg-purple bg-opacity-10 max-w-mdd gap-5 flex-col">
        <div className="flex items-center gap-3">
          <img alt="orbit" src={Orbit} className="rounded  h-8 w-8" />
          <span className="font-medium text-white">Orbit Marketplace</span>
        </div>
        <div className="flex text-base  gap-5">
          <div className="flex w-44 flex-col  ">
            <span className="text-white">Volume Traded</span>
            <span className="text-white font-semibold text-xl">
              {aggregatedInfo().volumeSum} EVMOS
            </span>
          </div>
          <div className="flex w-44 flex-col  ">
            <span className="text-white">Transactions</span>
            <span className="text-white font-semibold text-xl">
              {aggregatedInfo()?.transactionSum}
            </span>
          </div>
        </div>
      </div>
    );
  }

  function RumbleStats({ rumbleInfo }) {
    return (
      <div className="flex p-3 border rounded w-full border-darkGray2 bg-purple bg-opacity-10 max-w-mdd gap-5 flex-col">
        <div className="flex items-center gap-3">
          <img alt="orbit" src={Rumble} className="rounded  h-8 w-8" />
          <span className="font-medium text-white">Rumble</span>
        </div>
        <div className="flex text-base  gap-5">
          <div className="flex w-44 flex-col  ">
            <span className="text-white">Tickets Volume</span>
            <span className="text-white font-semibold text-xl">
              {ethers.utils.formatEther(rumbleInfo?.volume ?? 0)} EVMOS
            </span>
          </div>
          <div className="flex w-44 flex-col  ">
            <span className="text-white">Raffles Won</span>
            <span className="text-white font-semibold text-xl">
              {rumbleInfo?.winCount}
            </span>
          </div>
          <div className="flex w-44 flex-col  ">
            <span className="text-white">Raffles Played</span>
            <span className="text-white font-semibold text-xl">
              {rumbleInfo?.participationCount}
            </span>
          </div>
        </div>
      </div>
    );
  }

  function OACard({ id }) {
    const tokenData = useTokenData(id, ORBITAL_APES_CONTRACT);
    return (
      <div className="flex border gap-1 p-1 border-medium rounded flex-col">
        <span className="text-white font-semibold text-center">OA #{id}</span>
        <img alt="OrbitalApe" className="w-20" src={tokenData?.image} />
      </div>
    );
  }

  function OrbitalApes({ ownedOAs }) {
    const [showAll, setShowAll] = useState(false);

    const displayedNFTS = () => {
      if (!showAll) {
        return ownedOAs.slice(0, 9);
      } else {
        return ownedOAs;
      }
    };

    return (
      <div className="flex  max-w-4xl  gap-3 flex-col">
        <div className="flex flex-col">
          <span className="text-white font-semibold text-2xl">
            Orbital Apes
          </span>
        </div>
        <div className="flex flex-wrap gap-2.5">
          {displayedNFTS().map((v, i) => (
            <OACard id={v} key={i} />
          ))}
        </div>
        <button
          onClick={() => {
            setShowAll(!showAll);
          }}
          className="text-center  text-purple text-sm"
        >
          Show All
        </button>
      </div>
    );
  }

  function Header({ ownedOAs, stakedBalance, rewardsClaimed, mintInfo }) {
    return (
      <div className="flex flex-wrap gap-5">
        <div className="flex w-44 flex-col border border-yellow rounded-md px-4 py-2.5 bg-yellow bg-opacity-5">
          <span className="text-white">OA Minted</span>
          <div className="flex items-center gap-2">
            <img className="w-4" alt="apeprofile" src={ApeProfile} />
            <span className="font-semibold text-white text-lg">
              {mintInfo?.amount ?? 0}
            </span>
          </div>
        </div>
        <div className="flex w-44 flex-col border border-purple rounded-md px-4 py-2.5 bg-purple bg-opacity-5">
          <span className="text-white">OA Count</span>
          <div className="flex items-center gap-2">
            <img className="w-4" alt="apeprofile" src={ApeProfile} />
            <span className="font-semibold text-white text-lg">
              {ownedOAs?.length}
            </span>
          </div>
        </div>
        <div className="flex w-44 flex-col border border-purple rounded-md px-4 py-2.5 bg-purple bg-opacity-5">
          <span className="text-white">Staked with OA</span>
          <span className="text-white font-semibold text-xl">
            {nFormatter(stakedBalance, 2)} EVMOS
          </span>
        </div>
        <div className="flex w-44 flex-col border border-purple rounded-md px-4 py-2.5 bg-purple bg-opacity-5">
          <span className="text-white">Rewards Claimed</span>
          <span className="text-white font-semibold text-xl">
            {rewardsClaimed.toFixed(2)} EVMOS
          </span>
        </div>
      </div>
    );
  }

  function PublicProfile({
    ownedOAs,
    stakedBalance,
    rumbleInfo,
    rewardsClaimed,
    orbitInfo,
    mintInfo,
  }) {
    return (
      <div className="flex flex-col gap-14">
        <div className="flex flex-col gap-5">
        <div className="flex gap-4 items-center">
            <Identicon account={address} size={100} />

            <div className="flex flex-col">
              <div className="flex text-white flex-col">
                <Address
                  address={address}
                  length={5}
                  ellipsis={true}
                  showLabel={true}
                />
              </div>
            </div>
          </div>
          <Header
            mintInfo={mintInfo}
            rewardsClaimed={rewardsClaimed}
            stakedBalance={stakedBalance}
            ownedOAs={ownedOAs}
          />
        </div>
        <div className="flex gap-14 flex-col">
          {ownedOAs?.length > 0 && <OrbitalApes ownedOAs={ownedOAs} />}
          <EcosystemStats orbitInfo={orbitInfo} rumbleInfo={rumbleInfo} />
        </div>
      </div>
    );
  }

  function PrivateProfile({
    ownedOAs,
    stakedBalance,
    rumbleInfo,
    rewardsClaimed,
    orbitInfo,
    mintInfo,
  }) {
    return (
      <div className="flex flex-col gap-14">
        <div className="flex flex-col gap-5">
          <div className="flex gap-4 items-center">
            <Identicon account={address} size={100} />

            <div className="flex flex-col">
              <div className="flex text-white flex-col">
                <Address
                  address={address}
                  length={5}
                  ellipsis={true}
                  showLabel={true}
                />
              </div>
            </div>
          </div>
          <Header
            mintInfo={mintInfo}
            rewardsClaimed={rewardsClaimed}
            stakedBalance={stakedBalance}
            ownedOAs={ownedOAs}
          />
        </div>
        <OwnerDiscord address={useraddress} />

        <div className="flex gap-14 flex-col">
          <OrbitalApes ownedOAs={ownedOAs} />
          <EcosystemStats orbitInfo={orbitInfo} rumbleInfo={rumbleInfo} />
        </div>
      </div>
    );
  }

  return (
    <div className="flex gap-10 pt-8 px-6 pb-44 flex-col w-full">
      {privateProfile && (
        <PrivateProfile
          mintInfo={mintInfo}
          orbitInfo={orbitInfo}
          rewardsClaimed={rewardsClaimed}
          rumbleInfo={rumbleInfo}
          stakedBalance={stakedBalance}
          ownedOAs={ownedOAs}
        />
      )}
      {!privateProfile && (
        <PublicProfile
          mintInfo={mintInfo}
          orbitInfo={orbitInfo}
          rewardsClaimed={rewardsClaimed}
          rumbleInfo={rumbleInfo}
          stakedBalance={stakedBalance}
          ownedOAs={ownedOAs}
        />
      )}
    </div>
  );
}
