import { Link } from "react-router-dom";
import cs from "classnames";
import Discord from "../assets/icons/discord.svg";
import Twitter from "../assets/icons/twitter.svg";
import Orbit from "../assets/branding/Logo.svg";
import Rumble from "../assets/hub/Logo.svg";
import ApeProfile from "../assets/hub/apeProfile.svg";
import Oav from "../assets/tokens/oavwhite.svg";
import Oa from "../assets/branding/OAwhite.svg";
import CashPrize from "../assets/icons/CashPrize.svg";
import Stats from "../assets/icons/stats-chart.svg";

export default function SideMenu({ address, path }) {
  return (
    <div className="md:flex hidden relative bg-dimPurple pt-5 w-44 p-3 px-0  flex-col">
      <span className="text-white px-4 h-12 font-semibold overflow-y-scroll fixed top-5 text-3xl">
        OA Hub
      </span>

      <div className="flex  overflow-y-scroll fixed top-20 py-5  gap-0.5 flex-col">
        <Link
          to={"/"}
          className={cs(
            {
              "bg-opacity-50": path === "/",
            },
            "flex bg-opacity-0 bg-purple hover:bg-opacity-30 py-3 px-4 w-44 hover:bg-purple items-center gap-3"
          )}
        >
          <div className="w-8 bg-dimPurple flex items-center justify-center p-1 h-8 border rounded-lg border-purple">
            <img alt="orbit" className="h-8" src={Oa} />
          </div>
          <span className="text-white text-base font-medium ">Home</span>
        </Link>

        <Link
          to={"/profile/" + address}
          className={cs(
            {
              "bg-opacity-50": path === "/Profile".toLocaleLowerCase(),
            },
            "flex bg-purple bg-opacity-0 hover:bg-opacity-30 py-3 px-4 w-44 hover:bg-purple items-center gap-3"
          )}
        >
          <div className="w-8 bg-dimPurple flex items-center justify-center p-1.5 h-8 border rounded-lg border-purple">
            <img alt="ape counter" className="h-8" src={ApeProfile} />
          </div>
          <span className="text-white text-base font-medium ">Profile</span>
        </Link>
        <Link
          to={"/rewards"}
          className={cs(
            {
              "bg-opacity-50": path === "/Rewards".toLocaleLowerCase(),
            },
            "flex bg-purple bg-opacity-0 hover:bg-opacity-30 py-3 px-4 w-44 hover:bg-purple items-center gap-3"
          )}
        >
          <div className="w-8 bg-dimPurple flex items-center justify-center p-1 h-8 border rounded-lg border-purple">
            <img alt="orbit" className="h-8" src={CashPrize} />
          </div>{" "}
          <span className="text-white text-base font-medium ">Rewards</span>
        </Link>
        <Link
          to={"/stats"}
          className={cs(
            {
              "bg-opacity-50": path === "/Stats".toLocaleLowerCase(),
            },
            "flex bg-purple bg-opacity-0 hover:bg-opacity-30 py-3 px-4 w-44 hover:bg-purple items-center gap-3"
          )}
        >
          <div className="w-8 bg-dimPurple flex items-center justify-center p-1.5 h-8 border rounded-lg border-purple">
            <img alt="ape counter" className="h-8" src={Stats} />
          </div>{" "}
          <span className="text-white text-base font-medium ">Stats</span>
        </Link>
        <Link
          to={{ pathname: "https://www.orbitmarket.io" }}
          target="_blank"
          className={cs(
            {
              "bg-opacity-50": path === "",
            },
            "flex bg-purple bg-opacity-0 hover:bg-opacity-30 py-3 px-4 w-44 hover:bg-purple items-center gap-3"
          )}
        >
          <div className="w-8 bg-dimPurple flex items-center justify-center p-1.5 h-8 border rounded-lg border-purple">
            <img alt="orbit" className="h-8" src={Orbit} />
          </div>
          <span className="text-white text-base font-medium ">Orbit</span>
        </Link>
        <Link
          to={{ pathname: "https://www.orbitrumble.com" }}
          target="_blank"
          className={cs(
            {
              "bg-opacity-50": path === "",
            },
            "flex bg-purple bg-opacity-0 hover:bg-opacity-30 py-3 px-4 w-44 hover:bg-purple items-center gap-3"
          )}
        >
          <div className="w-8 bg-dimPurple flex items-center justify-center p-1.5 h-8 border rounded-lg border-purple">
            <img alt="rumble" className="h-8" src={Rumble} />
          </div>
          <span className="text-white text-base font-medium ">Rumble</span>
        </Link>
        <Link
          to={{ pathname: "https://www.orbitalapes.com/store" }}
          target="_blank"
          className={cs(
            {
              "bg-opacity-50": path === "",
            },
            "flex bg-purple bg-opacity-0 hover:bg-opacity-30 py-3 px-4 w-44 hover:bg-purple items-center gap-3"
          )}
        >
          <div className="w-8 bg-dimPurple flex items-center justify-center p-1 h-8 border rounded-lg border-purple">
            <img alt="rumble" className="h-8" src={Oav} />
          </div>
          <span className="text-white text-base font-medium ">OAV Store</span>
        </Link>
      </div>

      <div className="flex self-center mt-auto pr-10 fixed bottom-10 gap-4">
        <a
          href="https://discord.gg/orbitalapes "
          className=" w-7 h-7 rounded-full"
        >
          <img className="w-full" alt="discord" src={Discord} />
        </a>

        <a
          href="https://twitter.com/OrbitalApes"
          className=" w-6 h-6 rounded-full"
        >
          <img className="w-full" alt="twitter" src={Twitter} />
        </a>
      </div>
    </div>
  );
}
