import AggGraph from "components/hub/AggGraph";
import { ethers } from "ethers";
import { OrbitDailyRewards, OrbitDailyVolume, OrbitStats, TopTradersByVolume } from "graphql/api";
import { useEffect, useState } from "react";
import { nFormatter } from "../formatNumber";
import TopUsers from "./TopUsers";


export default function OrbitStatsPage({totalRewards}) {

    const [topTraders, setTopTraders] = useState([])
    const [orbitDailyVolume, setOrbitDailyVolume] = useState([])
    const [orbitDailyRewards, setOrbitDailyRewards] = useState([])
    const [orbitStats, setOrbitStats] = useState([])


    useEffect(() => { 
      TopTradersByVolume().then(tt => {
        setTopTraders(tt)
      })
      OrbitDailyVolume().then(odv => {
        setOrbitDailyVolume(odv)
      })
      OrbitDailyRewards().then(odr => {
        setOrbitDailyRewards(odr)
      })
      OrbitStats().then(os => {
        setOrbitStats(os)
      })

    }, [])

    function Header({orbitStats}) {

      const total = () => {
        let sum = 0;
        totalRewards.forEach((r) => {
          if (r.type === "ORBIT") {
            const _amount = parseFloat(
              ethers.utils.formatEther(r.amountDeposited ?? 0)
            );
            sum += _amount;
          }
        });
  
        return (sum).toFixed(2);
      };

      const totalVolume = () => {
        let sum = 0;
        orbitStats.forEach((r) => {
            const _amount = parseFloat(
              ethers.utils.formatEther(r.sellsVolume ?? 0)
            );
            sum += _amount;
        });
  
        return (sum).toFixed(2);
      } 


      const totalTrades = () => {
        let sum = 0;
        orbitStats.forEach((r) => {
            const _amount = parseFloat(
              r.sellsCount ?? 0
            );
            sum += _amount;
        });
  
        return (sum)
      } 

      const totalUsers = () => {
        let sum = 0;
        orbitStats.forEach((r) => {
            const _amount = parseFloat(
              r.numAddresses ?? 0
            );
            sum += _amount;
        });
  
        return (sum)
      } 

      return (
        <div className="flex flex-wrap gap-5">
          <div className="flex w-44 flex-col border border-purple rounded-md px-4 py-2.5 bg-purple bg-opacity-5">
            <span className="text-white">Total Volume</span>
            <span className="text-white font-semibold text-xl">{nFormatter(totalVolume(), 2)} EVMOS</span>
          </div>
          <div className="flex w-44 flex-col border border-purple rounded-md px-4 py-2.5 bg-purple bg-opacity-5">
            <span className="text-white">Total Trades</span>
            <span className="text-white font-semibold text-xl">{totalTrades()}</span>
          </div>
          <div className="flex w-44 flex-col border border-purple rounded-md px-4 py-2.5 bg-purple bg-opacity-5">
            <span className="text-white">Unique Users</span>
            <span className="text-white font-semibold text-xl">{totalUsers()}</span>
          </div>
          <div className="flex w-44 flex-col border border-purple rounded-md px-4 py-2.5 bg-purple bg-opacity-5">
            <span className="text-white">Rewards Distributed</span>
            <span className="text-white font-semibold text-xl">{total()} EVMOS</span>
          </div>
        </div>
      );
    }
  
    function TradeVolume({orbitDailyVolume}) {
      return (
        <div className="flex flex-1 gap-2 flex-col">
          <span className="text-white text-xl font-medium">Trade Volume</span>
          <div className="h-56 border p-2 border-purple rounded-md">
            <AggGraph
              height={190}
              data={orbitDailyVolume}
              aggKey={"sellsVolume"}
              title={"Total EVMOS Volume"}
            />
          </div>
        </div>
      );
    }
  
    function RewardsDistributed({orbitDailyRewards}) {
      return (
        <div className="flex flex-1 gap-2 flex-col">
          <span className="text-white text-xl font-medium">
            Rewards Distributed
          </span>
          <div className="h-56 border p-2 border-purple rounded-md">
            <AggGraph
              height={190}
              data={orbitDailyRewards}
              aggKey={"amountDeposited"}
              title={"Total EVMOS Rewards"}
            />
          </div>
        </div>
      );
    }
  
    return (
      <div className="flex flex-col gap-10">
        <span className="text-white font-semibold text-2xl">
          Orbit Marketplace
        </span>
        <Header orbitStats={orbitStats} />
        <div className="flex flex-col mdd:flex-row pt-5 flex-1 gap-5">
          <TopUsers label={"EVMOS"} formatEth={true} dataKey={"volume"} selectKey="address" topHolders={topTraders} title={"Top Traders by Volume"} />
  
          <div className="flex flex-1 gap-4 flex-col">
            <TradeVolume orbitDailyVolume={orbitDailyVolume} />
            <RewardsDistributed orbitDailyRewards={orbitDailyRewards} />
          </div>
        </div>
      </div>
    );
  }