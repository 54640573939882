import cs from "classnames";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { nFormatter } from "../../helpers/formatNumber";
import {useEVM} from  "../../hooks/EVMhook"

export default function Validator({
  orbitalBalance,
  onClaim,
  loading,
}) {

  const [userRewards, setRewards] = useState({})
  const {rewardsContract:rewardDistributorContract} = useEVM()

  useEffect(() => {
    async function fetchInfo() {
      const evmosRewards = await rewardDistributorContract?.instance
        ?.attach("0x4C4869C43112768024A6516CbCD328D9808ecDD7")
        ?.getRewardsBalances(ethers?.constants?.AddressZero);
      const toriRewards = await rewardDistributorContract?.instance
        ?.attach("0x4C4869C43112768024A6516CbCD328D9808ecDD7")
        ?.getRewardsBalances("0x205CF44075E77A3543abC690437F3b2819bc450a");
      const osmoRewards = await rewardDistributorContract?.instance
        ?.attach("0x4C4869C43112768024A6516CbCD328D9808ecDD7")
        ?.getRewardsBalances("0xfa3c22c069b9556a4b2f7ece1ee3b467909f4864");
      setRewards({evmos: ethers.utils.formatEther(evmosRewards), tori: ethers.utils.formatUnits(toriRewards, 6), osmo: ethers.utils.formatUnits(osmoRewards, 6)});

    }
    if ( rewardDistributorContract?.instance) {
      fetchInfo();
    }
  }, [rewardDistributorContract?.instance]);


  return (
    <div 
    className="flex mdd:w-6/12 w-full flex-col gap-3">
      <span className="text-white font-semibold text-2xl">
        Claim Validator Rewards
      </span>
      <div className="rounded flex-1 bg-dark border-2 border-darkGray  p-4 gap-7 flex flex-col shadow">
        <div className="flex gap-3 flex-1 w-full flex-col">

          <div className="flex items-end text-white flex-1 justify-between">
            <span>My EVMOS Rewards</span>
            <div className="border-b border-gray border-dotted flex-1 mx-2 mb-1" />
            <div className="flex gap-3">
              <span className="border h-7 rounded border-purple px-1.5">
                {nFormatter(userRewards["evmos"], 3)}
              </span>

              <button
                disabled={loading === "evmos"}
                onClick={() => onClaim("evmos")}
                className={cs(
                  "bg-purple h-7 rounded px-5  text-white font-medium",
                  {
                    "opacity-50": loading === "evmos",
                  }
                )}
              >
                {loading === "evmos" ? "Loading..." : `Claim`}
              </button>
            </div>
          </div>
          <div className="flex items-end text-white flex-1 justify-between">
            <span>My TORI Rewards</span>
            <div className="border-b border-gray border-dotted flex-1 mx-2 mb-1" />
            <div className="flex gap-3">
              <span className="border h-7 rounded border-purple px-1.5">
                {nFormatter(userRewards["tori"], 3)}
              </span>

              <button
                onClick={() => onClaim("tori")}
                className={cs(
                  "bg-purple h-7 rounded px-5  text-white font-medium",
                  { "opacity-50": loading === "tori" }
                )}
              >
                {loading === "tori" ? "Loading..." : `Claim`}
              </button>
            </div>
          </div>
          <div className="flex items-end text-white flex-1 justify-between">
            <span>My OSMO Rewards</span>
            <div className="border-b border-gray border-dotted flex-1 mx-2 mb-1" />
            <div className="flex gap-3">
              <span className="border h-7 rounded border-purple px-1.5">
                {nFormatter(userRewards["osmo"], 3)}
              </span>

              <button
                onClick={() => onClaim("osmo")}
                className={cs(
                  "bg-purple h-7 rounded px-5  text-white font-medium",
                  { "opacity-50": loading === "osmo" }
                )}
              >
                {loading === "osmo" ? "Loading..." : `Claim`}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
