import { fetchData } from "lambda/lambda";
import { useEffect, useState } from "react";
import ProposalRow from "./ProposalRow";
import { Link } from "react-router-dom";

export default function Proposals() {
  const [proposals, setProposals] = useState([]);

  useEffect(() => {
    fetchData(
      "https://evmos-rest.publicnode.com/cosmos/gov/v1/proposals?pagination.limit=5&pagination.reverse=true"
    ).then((p) => {
      setProposals(p?.proposals);
    });
  }, []);

  return (
    <div className="flex flex-1">
      <div className="flex  w-full flex-col gap-3">
        <div className="text-white flex justify-between font-semibold text-2xl">
          EVMOS Proposals
          <Link
            to={{ pathname: "https://www.mintscan.io/evmos/proposals" }}
            target="_blank"
          >
            <button className="rounded px-1.5 text-base border bg-purple bg-opacity-20 border-purple">
              See in Mintscan
            </button>
          </Link>
        </div>
        {proposals?.slice(0, 5)?.map((p, index) => {
          return <ProposalRow proposal={p} key={index} />;
        })}
      </div>
    </div>
  );
}
