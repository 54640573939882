import {
  Legend,
  XAxis,
  YAxis,
  Tooltip as ChartTooltip,
  CartesianGrid,
  Area,
  AreaChart,
} from "recharts";
import ReactResizeDetector from "react-resize-detector";
import moment from "moment";
import { ethers } from "ethers";

export default function AggGraph({ data = [], title, aggKey, height, legend }) {
  const sorted = [...data];
  let sum = 0;
  const volumeSum = sorted
    ?.sort((a, b) => parseInt(a?.timestamp) - parseInt(b?.timestamp))
    .map((d) => {
      sum += parseFloat(ethers.utils.formatEther(d[aggKey]));
      data = {
        date: moment(
          new Date(parseInt(d?.timestamp) * 1000).toISOString()
        ).format("D/M"),
      };

      data[aggKey] = parseFloat(sum.toFixed(2));

      return data;
    });


  return (
    <div className="flex w-full text-white rounded-lg  flex-col">
      <div className=" flex flex-col ">
        <ReactResizeDetector>
          {({ width }) =>
            width > 0 ? (
              <div
                style={{ height: height }}
                className="py-3   overflow-hidden relative"
              >
                <div className="absolute overflow-hidden">
                  <AreaChart
                    syncId={title}
                    width={width}
                    height={height}
                    data={volumeSum}
                    margin={{
                      top: 5,
                      right: 20,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <defs>
                      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop
                          offset="5%"
                          stopColor="#8884d8"
                          stopOpacity={0.8}
                        />
                        <stop
                          offset="95%"
                          stopColor="#8884d8"
                          stopOpacity={0}
                        />
                      </linearGradient>
                    </defs>
                    <XAxis dataKey="date" />
                    <YAxis />
                    {legend && <Legend />}
                    <ChartTooltip />

                    <CartesianGrid stroke="#343444" />
                    <Area
                      type="monotone"
                      dataKey={aggKey}
                      stroke="#8884d8"
                      fillOpacity={1}
                      fill="url(#colorUv)"
                    />
                  </AreaChart>
                </div>
              </div>
            ) : (
              <div>{width}</div>
            )
          }
        </ReactResizeDetector>
      </div>
    </div>
  );
}
