import { ethers } from "ethers";
import Evmos from "../assets/tokens/EVMOS.svg";
import WEvmos from "../assets/tokens/WEVMOS.svg";
import Oav from "../assets/tokens/oav.png";
import Tori from "../assets/tokens/tori.svg"
import Atom from "../assets/tokens/atom.png";
import Osmo from "../assets/tokens/osmo.svg";
import Diff from "../assets/tokens/diff.svg";
import { isProd } from "./env";
import { isSameAddress } from "./address";

const PROD_ATOM_ADDRESS = "0xc5e00d3b04563950941f7137b5afa3a534f0d6d6";

const PROD_DEFAULT_ERC20 = "0xd4949664cd82660aae99bedc034a0dea8a0bd517";

const TEST_BUNDLE_ADDRESS = "0x00526278200ae52428b43B542b3D43A55Fcf3aF8";

const PROD_BUNDLE_ADDRESS = "0x00526278200ae52428b43B542b3D43A55Fcf3aF8";
const PROD_OSMO_ADDRESS = "0xfa3c22c069b9556a4b2f7ece1ee3b467909f4864";

export const ORBITAL_APES_CONTRACT = "0x4c275ade386af17276834579b1a68146cef3c770"

export const DEFAULT_ERC20 =  PROD_DEFAULT_ERC20

export const ZERO_ADDRESS = ethers.constants.AddressZero;

export const BUNDLE_ADDRESS = isProd
  ? PROD_BUNDLE_ADDRESS
  : TEST_BUNDLE_ADDRESS;


const prodTokenMeta = {
  "0x0000000000000000000000000000000000000000": {
    image: Evmos,
    name: "EVMOS",
    pair: "0x6DF94e977D82d917a621DFf75C00aBd19A27c26B",
    erc: false,
    class: "h-3",
    wrapped: false,
    min: 10,
  },
  "0xd4949664cd82660aae99bedc034a0dea8a0bd517": {
    image: WEvmos,
    name: "WEVMOS",
    pair: "0x6DF94e977D82d917a621DFf75C00aBd19A27c26B",
    erc: true,
    class: "h-3",
    wrapped: false,
    min: 10,
  },
  "0xbbd37bf85f7474b5bde689695674fab1888565ad": {
    image: Oav,
    name: "OAV",
    pair: "0x918D1b960BdB5e3e77e69b99DCf4F1C6Aa90a41c",
    class: "h-4",
    erc: true,
    wrapped: false,
    min: 5000,
  },
  "0x205CF44075E77A3543abC690437F3b2819bc450a": {
    image: Tori,
    name: "TORI",
    pair: "0x918D1b960BdB5e3e77e69b99DCf4F1C6Aa90a41c",
    class: "h-4",
    erc: true,
    wrapped: false,
    hide: true,
    min: 20,
  },
  "0x3f75ceabcdfed1aca03257dc6bdc0408e2b4b026": {
    name: "DIFF",
    image: Diff,
    pair: "0x932c2d21fa11a545554301e5e6fb48c3accdff4d",
    class: "h-4",
    erc: true,
    wrapped: false,
    min: 5000,
  },
  "0xc5e00d3b04563950941f7137b5afa3a534f0d6d6": {
    name: "ATOM",
    image: Atom,
    pair: "0x9d3055544e85dbaea07a6966741010e663ab5444",
    class: "h-4",
    erc: true,
    wrapped: false,
    min: 10,
  },
  "0xfa3c22c069b9556a4b2f7ece1ee3b467909f4864": {
    name: "OSMO",
    image: Osmo,
    pair: "0x123063d3432171b125d17cafe4fb45e01b016953",
    class: "h-4",
    erc: true,
    wrapped: false,
    min: 75,
  },
};

export function formatEther(amount, payToken) {
  if (!amount) return "0";
  if (
    isSameAddress(payToken, PROD_ATOM_ADDRESS) ||
    isSameAddress(payToken, PROD_OSMO_ADDRESS)
  ) {
    return ethers.utils.formatUnits(amount, 6);
  } else {
    return ethers.utils.formatEther(amount);
  }
}

export function getEvmosAmount(currencyAmount, evmosPrice) {
  return parseFloat(currencyAmount) * parseFloat(evmosPrice);
}


export const payTokenMeta = prodTokenMeta ;
