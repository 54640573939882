import AggGraph from "components/hub/AggGraph";
import { ethers } from "ethers";
import { RumbleDailyRewards, RumbleDailyVolume, RumbleSummary, RumbleTopUsersByVolume } from "graphql/api";
import { isSameAddress } from "helpers/address";
import { nFormatter } from "helpers/formatNumber";
import {
  DEFAULT_ERC20,
  formatEther,
  getEvmosAmount,
  ZERO_ADDRESS,
} from "helpers/payTokens";
import { useEffect, useState } from "react";
import { useTokenPrice } from "../useTokenPrice";
import TopUsers from "./TopUsers";

export default function RumbleStats({ totalRewards }) {
  const [rumbleDailyRewards, setRumbleDailyRewards] = useState([]);
  const [rumbleSummaries, setRumbleSummaries] = useState([]);
  const { getTokenPrice } = useTokenPrice();
  const [rumbleTopUsers, setRumbleTopUsers] = useState([]);
  const [rumbleDailyVolume, setRumbleDailyVolume] = useState([]);

  useEffect(() => {
    RumbleTopUsersByVolume().then(rtu => {
      setRumbleTopUsers(rtu)
    })
    RumbleDailyVolume().then(rdv => {
      setRumbleDailyVolume(rdv)
    })
  }, [])

  useEffect(() => {
    RumbleDailyRewards().then((odr) => {
      setRumbleDailyRewards(odr);
    });
    RumbleSummary().then(async (rs) => {
      let data = rs.slice();
      let dataWithPrice = [];
      for (let i = 0; i < data.length; i++) {
        if (
          isSameAddress(data[i].payToken, DEFAULT_ERC20) ||
          isSameAddress(data[i].payToken, ZERO_ADDRESS)
        ) {
          dataWithPrice.push({
            ...data[i],
            evmosPrice: 1,
            etherVolume: formatEther(data[i].volume, data[i].payToken),
          });
        } else {
          const price = await getTokenPrice(data[i].payToken.toLowerCase());
          dataWithPrice.push({
            ...data[i],
            evmosPrice: price,
            etherVolume: formatEther(data[i].volume, data[i].payToken),
          });
        }
      }
      setRumbleSummaries(dataWithPrice);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function Header() {
    const total = () => {
      let sum = 0;
      totalRewards.forEach((r) => {
        if (r.type === "RUMBLE") {
          const _amount = parseFloat(
            ethers.utils.formatEther(r.amountDeposited ?? 0)
          );
          sum += _amount;
        }
      });

      return sum.toFixed(2);
    };

    const totalUserCount = () => {
      let count = 0;
      rumbleSummaries?.forEach((rs) => {
        count += rs.userCount;
      });
      return count;
    };

    const totalVolume = () => {
      let count = 0;
      rumbleSummaries?.forEach((rs) => {
        count += getEvmosAmount(rs.etherVolume, rs.evmosPrice);
      });
      return count;
    };

    const totalWinners = () => {
      let count = 0;
      rumbleSummaries?.forEach((rs) => {
        count += rs.winners;
      });
      return count;
    };

    return (
      <div className="flex flex-wrap gap-5">
        <div className="flex w-44 flex-col border border-purple rounded-md px-4 py-2.5 bg-purple bg-opacity-5">
          <span className="text-white">Total Ticket Volume</span>
          <span className="text-white font-semibold text-xl">
            {nFormatter(totalVolume(), 2)} EVMOS
          </span>
        </div>
        <div className="flex w-44 flex-col border border-purple rounded-md px-4 py-2.5 bg-purple bg-opacity-5">
          <span className="text-white">Total Winners</span>
          <span className="text-white font-semibold text-xl">
            {totalWinners()}
          </span>
        </div>
        <div className="flex w-44 flex-col border border-purple rounded-md px-4 py-2.5 bg-purple bg-opacity-5">
          <span className="text-white">Unique Users</span>
          <span className="text-white font-semibold text-xl">
            {totalUserCount()}
          </span>
        </div>
        <div className="flex w-44 flex-col border border-purple rounded-md px-4 py-2.5 bg-purple bg-opacity-5">
          <span className="text-white">Rewards Distributed</span>
          <span className="text-white font-semibold text-xl">{total()}</span>
        </div>
      </div>
    );
  }

  function TradeVolume({rumbleDailyVolume}) {
    return (
      <div className="flex flex-1 gap-2 flex-col">
        <span className="text-white text-xl font-medium">Trade Volume</span>
        <div className="h-56 border p-2 border-purple rounded-md">
          <AggGraph
            height={190}
            data={rumbleDailyVolume}
            aggKey={"volume"}
            title={"Ticket Volume"}
          />
        </div>
      </div>
    );
  }

  function RewardsDistributed({ rumbleDailyRewards }) {
    return (
      <div className="flex flex-1 gap-2 flex-col">
        <span className="text-white text-xl font-medium">
          Rewards Distributed
        </span>
        <div className="h-56 border p-2 border-purple rounded-md">
          <AggGraph
            height={190}
            data={rumbleDailyRewards}
            aggKey={"amountDeposited"}
            title={"Total EVMOS Rewards"}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-10">
      <span className="text-white font-semibold text-2xl">Rumble</span>
      <Header />
      <div className="flex flex-col mdd:flex-row pt-5 flex-1 gap-5">
        <TopUsers formatEth={true} selectKey={"id"} dataKey={"volume"} title="Top Users By Ticket Volume" topHolders={rumbleTopUsers}  />
        <div className="flex flex-1 gap-4 flex-col">
          <TradeVolume rumbleDailyVolume={rumbleDailyVolume} />
          <RewardsDistributed rumbleDailyRewards={rumbleDailyRewards} />
        </div>
      </div>
    </div>
  );
}
