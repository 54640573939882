import React, { useEffect } from "react";
import "./App.css";
import BaseLayout from "./layout/BaseLayout";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Symfoni } from "helpers/env";

import { Toaster } from "react-hot-toast";
import Validator from "./pages/Hub/rewards/Validator";
import Stats from "./pages/Hub/stats/OAStats";
import AOS from "aos";
import { useSelector } from "react-redux";
import FourOhFour from "pages/FourOhFour/FourOhFour";
import Home from "pages/Hub/Home/Home";
import Profile from "pages/Hub/Profile";

AOS.init({
  duration: 600,
});

function App() {
  const fourOhFourURL = useSelector((state) => state.fourOhFour.path);
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.removeAllListeners();
      window.ethereum.on("chainChanged", () => {
        document.location.reload();
      });
      window.ethereum.on("accountsChanged", () => {
        document.location.reload();
      });
    }
  }, []);

  function renderApp() {
    return (
      <Symfoni autoInit={false} showLoading={false}>
        <Toaster
          toastOptions={{
            className: "",
            style: {
              background: "#121619",
              border: "1px solid #21262a",
              padding: "16px",
              color: "#fff",
            },
          }}
          position="top-right"
        />

        <Switch>
          {fourOhFourURL != null && (
            <Route exact path={fourOhFourURL}>
              <FourOhFour />
            </Route>
          )}
          <Route exact path="/rewards">
            <BaseLayout>
              <Validator />
            </BaseLayout>
          </Route>
          <Route exact path="/stats">
            <BaseLayout>
              <Stats />
            </BaseLayout>
          </Route>
          <Route exact path="/profile/:useraddress">
            <BaseLayout>
              <Profile />
            </BaseLayout>
          </Route>
          <Route exact path="/">
            <BaseLayout>
              <Home />
            </BaseLayout>
          </Route>
          <Route exact path="*">
            <FourOhFour />
          </Route>
        </Switch>
      </Symfoni>
    );
  }

  return <BrowserRouter>{renderApp()}</BrowserRouter>;
}

export default App;
