import { getName } from "helpers/address";
import { useEffect, useState } from "react";

export default function Address({ address, length, ellipsis, showLabel }) {
  const [name, setName] = useState(null);

  function formatAddress() {
    return ellipsis
      ? `${address?.substring(0, length)}...${address?.substring(
          42 - length,
          42
        )}`
      : address?.substring(0, length);
  }

  useEffect(() => {
    (async () => {
      if (address && length) {
        const _name = await getName(address, length);
        setName(_name);
      }
    })();
  }, [address, length]);

  return (
    <div className="flex flex-col">
      {name && showLabel && <span>Evmos Domain:</span>}
      {`${name ? name : formatAddress()}`}
    </div>
  );
}
