import Address from "components/Address";
import { useEVM } from "hooks/EVMhook";
import ApeProfile from "../assets/hub/apeProfile.svg";
import User from "../assets/icons/contact.svg";
import cs from "classnames";

export default function TopBar({ ownedOAs }) {
  const { address, isMetamask, isConnected, isNetwork, addNetwork } = useEVM();

  return (
    <div className="flex z-10 fixed top-0 bg-dark left-44 right-0 gap-3 py-3 pt-6 pr-6 justify-end items-center">
      <div className="flex gap-1.5">
        <img className="w-4" alt="apeprofile" src={ApeProfile} />
        <span className="text-white font-semibold text-lg">
          {ownedOAs.length}
        </span>
      </div>
      <div className="w-44  flex self-end border py-1 px-1.5 text-white border-purple rounded">
        {isConnected && isNetwork && (
          <div className="flex gap-2 items-center">
            <img
              className="w-7 h-7 p-1 rounded-full border border-purple"
              alt="User"
              src={User}
            />
            <Address address={address} length={11} />
          </div>
        )}
        {(!isConnected || !isNetwork) && (
          <div
            onClick={() => {
              return isMetamask
                ? addNetwork()
                : window.open(
                    "https://evmos.dev/guides/keys-wallets/metamask.html",
                    "_blank"
                  );
            }}
            className={cs(
              "flex cursor-pointer items-center gap-2 p-1 mdd:pr-2.5 rounded-full  border-white"
            )}
          >
            <img
              className="w-7 h-7 p-1 rounded-full border border-purple"
              alt="User"
              src={User}
            />
            <span className="text-white flex text-sm">
              {!isMetamask && <>Install Metamask</>}
              {isConnected && isNetwork && (
                <Address address={address} length={11} />
              )}
              {isNetwork && !isConnected && <>Connect Wallet</>}
              {!isNetwork && isMetamask &&  <>Switch to EVMOS</>}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
