import Address from "components/hub/AddressHelper";
import { ethers } from "ethers";
import { nFormatter } from "helpers/formatNumber";
import { Link } from "react-router-dom";

export default function TopUsers({
  title,
  topHolders,
  selectKey,
  dataKey,
  formatEth,
  label,
}) {
  return (
    <div className="flex w-85 flex-col gap-2">
      <span className="text-white text-xl font-medium">{title}</span>
      <div className="flex gap-2 p-3 border border-purple rounded-md flex-col">
        {topHolders?.map((v, index) => (
          <Link
            to={"/profile/" + v[selectKey]}
            key={index}
            className="flex py-2 px-2.5 rounded-md bg-purple bg-opacity-10 hover:bg-opacity-30 text-base text-white"
          >
            <div className="w-52 gap-1 flex">
              {index + 1}. <Address length={8} address={v[selectKey]} />
            </div>
            <span className="w-32 text-right">
              {formatEth
                ? nFormatter(ethers.utils.formatEther(v[dataKey]) ?? 0, 2)
                : v[dataKey]}{" "}
              {label}
            </span>
          </Link>
        ))}
      </div>
    </div>
  );
}
