import { Link } from "react-router-dom";
import cs from "classnames";
import Discord from "../assets/icons/discord.svg";
import Twitter from "../assets/icons/twitter.svg";
import Orbit from "../assets/branding/Logo.svg";
import Rumble from "../assets/hub/Logo.svg";
import ApeProfile from "../assets/hub/apeProfile.svg";
import { useState } from "react";
import Hamburger from "components/hub/Hamburger";
import close from "../assets/icons/close.svg";
import Oav from "../assets/tokens/oavwhite.svg";
import Oa from "../assets/branding/OAwhite.svg";
import CashPrize from "../assets/icons/CashPrize.svg";
import Stats from "../assets/icons/stats-chart.svg";


export default function MobileSideMenu({ address, path }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {!isOpen && <Hamburger onClick={() => setIsOpen(true)} />}
      {isOpen && (
        <div className="flex z-20 md:hidden fixed bg-dimPurple pt-5 top-0 bottom-0 right-0 left-0  p-3 px-0  flex-col">
          <div className="flex  justify-between">
            <span className="text-white px-4 h-12 font-semibold  fixed top-5 text-3xl">
              OA Hub
            </span>
            <img
              onClick={() => {
                setIsOpen(false);
              }}
              className="w-5 cursor-pointer fixed top-8 right-6"
              alt="close"
              src={close}
            />
          </div>

          <div className="flex  overflow-y-scroll fixed top-20 py-5  gap-0.5 flex-col">
            <Link
              to={"/"}
              className={cs(
                {
                  "bg-opacity-50": path === "/",
                },
                "flex bg-opacity-0 bg-purple hover:bg-opacity-30 py-3 px-4 w-44 hover:bg-purple items-center gap-3"
              )}
            >
              <div className="w-8 bg-dimPurple flex items-center justify-center p-1 h-8 border rounded-lg border-purple">
                <img alt="orbit" className="h-8" src={Oa} />
              </div>{" "}
              <span className="text-white text-base font-medium ">Home</span>
            </Link>

            <Link
              to={"/profile/" + address}
              className={cs(
                {
                  "bg-opacity-50": path === "/Profile".toLocaleLowerCase(),
                },
                "flex bg-purple bg-opacity-0 hover:bg-opacity-30 py-3 px-4 w-44 hover:bg-purple items-center gap-3"
              )}
            >
              <div className="w-8 bg-dimPurple flex items-center justify-center p-1.5 h-8 border rounded-lg border-purple">
                <img alt="ape counter" className="h-8" src={ApeProfile} />
              </div>
              <span className="text-white text-base font-medium ">Profile</span>
            </Link>
            <Link
              to={"/rewards"}
              className={cs(
                {
                  "bg-opacity-50": path === "/Rewards".toLocaleLowerCase(),
                },
                "flex bg-purple bg-opacity-0 hover:bg-opacity-30 py-3 px-4 w-44 hover:bg-purple items-center gap-3"
              )}
            >
              <div className="w-8 bg-dimPurple flex items-center justify-center p-1 h-8 border rounded-lg border-purple">
                <img alt="orbit" className="h-8" src={CashPrize} />
              </div>

              <span className="text-white text-base font-medium ">Rewards</span>
            </Link>
            <Link
              to={"/stats"}
              className={cs(
                {
                  "bg-opacity-50": path === "/Stats".toLocaleLowerCase(),
                },
                "flex bg-purple bg-opacity-0 hover:bg-opacity-30 py-3 px-4 w-44 hover:bg-purple items-center gap-3"
              )}
            >
          <div className="w-8 bg-dimPurple flex items-center justify-center p-1.5 h-8 border rounded-lg border-purple">
            <img alt="ape counter" className="h-8" src={Stats} />
          </div>{" "}              <span className="text-white text-base font-medium ">Stats</span>
            </Link>
            <Link
              to={{ pathname: "https://www.orbitmarket.io" }}
              target="_blank"
              className={cs(
                {
                  "bg-opacity-50": path === "",
                },
                "flex bg-purple bg-opacity-0 hover:bg-opacity-30 py-3 px-4 w-44 hover:bg-purple items-center gap-3"
              )}
            >
              <div className="w-8 bg-dimPurple flex items-center justify-center p-1.5 h-8 border rounded-lg border-purple">
                <img alt="orbit" className="h-8" src={Orbit} />
              </div>
              <span className="text-white text-base font-medium ">Orbit</span>
            </Link>
            <Link
              to={{ pathname: "https://www.orbitrumble.com" }}
              target="_blank"
              className={cs(
                {
                  "bg-opacity-50": path === "",
                },
                "flex bg-purple bg-opacity-0 hover:bg-opacity-30 py-3 px-4 w-44 hover:bg-purple items-center gap-3"
              )}
            >
              <div className="w-8 bg-dimPurple flex items-center justify-center p-1.5 h-8 border rounded-lg border-purple">
                <img alt="rumble" className="h-8" src={Rumble} />
              </div>
              <span className="text-white text-base font-medium ">Rumble</span>
            </Link>
            <Link
              to={{ pathname: "https://www.orbitalapes.com/store" }}
              target="_blank"
              className={cs(
                {
                  "bg-opacity-50": path === "",
                },
                "flex bg-purple bg-opacity-0 hover:bg-opacity-30 py-3 px-4 w-44 hover:bg-purple items-center gap-3"
              )}
            >
          <div className="w-8 bg-dimPurple flex items-center justify-center p-1 h-8 border rounded-lg border-purple">
            <img alt="rumble" className="h-8" src={Oav} />
          </div>              <span className="text-white text-base font-medium ">
                OAV Store
              </span>
            </Link>
          </div>

          <div className="flex self-center mt-auto pr-10 fixed bottom-10 gap-4">
            <a
              href="https://discord.gg/orbitalapes "
              className=" w-7 h-7 rounded-full"
            >
              <img className="w-full" alt="discord" src={Discord} />
            </a>

            <a
              href="https://twitter.com/OrbitalApes"
              className=" w-6 h-6 rounded-full"
            >
              <img className="w-full" alt="twitter" src={Twitter} />
            </a>
          </div>
        </div>
      )}
    </>
  );
}
