import { GRAPH_URL } from "../constant/urls";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import {
  DEFAULT_ERC20,
  ZERO_ADDRESS,
  ORBITAL_APES_CONTRACT,
} from "helpers/payTokens";

const APIURL =
  "https://subgraph.satsuma-prod.com/09c9cf3574cc/orbital-apes/OrbitRumble/api";

const HUBURL = "https://subgraph.satsuma-prod.com/09c9cf3574cc/orbital-apes/OrbitRumble/api";

const TEST_API =
  "https://subgraph.satsuma-prod.com/09c9cf3574cc/orbital-apes/OrbitMarket/api";

const CollectionDailyStatsQuery = `
  query collectionDailyStats($collectionDailyStatFilter: CollectionDailyStat_filter ) {
    collectionDailyStats(first: 1000, where: $collectionDailyStatFilter) {
      id
      nftAddress
      volume
      count
      timestamp
      numOwners
    }
  }
`;

export const CollectionDailyStats = async () => {
  let obj = { nftAddress: ORBITAL_APES_CONTRACT };
  obj["payToken_in"] = [ZERO_ADDRESS, DEFAULT_ERC20];

  const client = new ApolloClient({
    uri: GRAPH_URL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(CollectionDailyStatsQuery),
    variables: {
      collectionDailyStatFilter: obj,
    },
  });

  return res?.data?.collectionDailyStats;
};

const UserOrbitStatsQuery = `
  query userOrbitStats($address: String) {
    userSummaries(where: {address: $address, type: "ALL"}) {
      id
      buyCount
      nftCount
      payToken
      sellCount
      transactionCount
      volume
    }
  }
`;

// payToken_in: ["0xd4949664cd82660aae99bedc034a0dea8a0bd517", "0x0000000000000000000000000000000000000000"

export const UserOrbitStats = async (address) => {
  const client = new ApolloClient({
    uri: TEST_API,
    cache: new InMemoryCache(),
  });

  let objs = {
    address,
  };

  const res = await client.query({
    query: gql(UserOrbitStatsQuery),
    variables: objs,
  });

  return res?.data?.userSummaries;
};

const UserRewardsSummaryQuery = `
  query userRewardSummary($address: String) {
    userRewardSummaries(where: {address: $address}) {
      address
      amountClaimed
      id
      payToken
      type
    }
  }
`;

export const UserRewardsSummary = async (address) => {
  const client = new ApolloClient({
    uri: HUBURL,
    cache: new InMemoryCache(),
  });

  let objs = {
    address,
  };

  const res = await client.query({
    query: gql(UserRewardsSummaryQuery),
    variables: objs,
  });

  return res?.data?.userRewardSummaries;
};

const TopOAHoldersQuery = `
  query topOaHolders($address: String) {
    erc721Balances(
      first: 10,
      where: {collection: "0x4c275ade386af17276834579b1a68146cef3c770"}
      orderBy: amount
      orderDirection: desc
    ) {
      id
      amount
      owner
    }
  }
`;

export const TopOAHolders = async () => {
  const client = new ApolloClient({
    uri: TEST_API,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(TopOAHoldersQuery),
  });

  return res?.data?.erc721Balances;
};

const RewardsSummaryQuery = `
  query rewardSummary {
    rewardSummaries(where: {payToken_in: ["0x0000000000000000000000000000000000000000", "0x00000000", "0xd4949664cd82660aae99bedc034a0dea8a0bd517"]}) {     
      type
      payToken
    amountDeposited
    }
  }
`;

export const RewardsSummary = async () => {
  const client = new ApolloClient({
    uri: HUBURL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(RewardsSummaryQuery),
  });

  return res?.data?.rewardSummaries;
};

const UserTokensQuery = `
  query userTokens($address: String, $nftAddress: String) {
    tokens(first:1000, where: {owner: $address, nftAddress: $nftAddress}) {
      id
      tokenId
    }
  }
`;

export const UserTokens = async (address) => {
  const client = new ApolloClient({
    uri: GRAPH_URL,
    cache: new InMemoryCache(),
  });

  let objs = {
    address,
    nftAddress: ORBITAL_APES_CONTRACT,
  };

  const res = await client.query({
    query: gql(UserTokensQuery),
    variables: objs,
  });

  return res?.data?.tokens;
};

export const RumbleUserInfo = async (address) => {
  const client = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(RumbleUserInfoQuery),
    variables: {
      address,
    },
  });

  return res.data.userInfo;
};

const RumbleSummaryQuery = `
query rumbleSummaries($address: String) {
  rumbleSummaries {
    id
    payToken
    poolsCreated
    timestamp
    userCount
    volume
    winners
  } 
}
`;

export const RumbleSummary = async (address) => {
  const client = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(RumbleSummaryQuery),
    variables: {
      address,
    },
  });

  return res.data.rumbleSummaries;
};

const RumbleUserInfoQuery = `
query rumbleUserInfo($address: String) {
  userInfo(id: $address) {
    participationCount
    poolCount
    volume
    winCount
  }
}
`;

const FloorPriceQuery = `
  query floor($listingFilter: Listing_filter) {
    listings(first: 1, orderBy: pricePerItem, orderDirection: asc, where: $listingFilter) {
      pricePerItem
      nftAddress
    }
  }
`;

export const GetFloorPrice = async (address) => {
  const client = new ApolloClient({
    uri: GRAPH_URL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(FloorPriceQuery),
    variables: {
      listingFilter: {
        active: true,
        nftAddress: address,
        payToken_in: [ZERO_ADDRESS, DEFAULT_ERC20],
      },
    },
  });
  return res?.data?.listings[0]?.pricePerItem;
};

const ListedItemsQuery = `
  query market($address: String) {
    collectionSummary(id: $address) {
      id
      listingsCount
      sellsCount
      sellsVolume
      nftCount
      numOwners
    }
  }
`;

export const CollectionStats = async (address) => {
  const client = new ApolloClient({
    uri: GRAPH_URL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(ListedItemsQuery),
    variables: {
      address,
    },
  });
  return res?.data?.collectionSummary;
};

const TopTradersByVolumeQuery = `
  query topTradersByVolume {
    userSummaries(orderBy: volume, orderDirection: desc, first: 10, where: {type: "ALL"}) {
      volume
      id
      payToken
      address
    }
  }
`;

export const TopTradersByVolume = async () => {
  const client = new ApolloClient({
    uri: TEST_API,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(TopTradersByVolumeQuery),
  });
  return res?.data?.userSummaries;
};

const OrbitDailyVolumeQuery = `
  query orbitDailyStats {
    orbitDailyStats(first: 1000, orderBy: timestamp, orderDirection: asc) {
      id
      sellsVolume
      timestamp
    }
  }
`;

export const OrbitDailyVolume = async () => {
  const client = new ApolloClient({
    uri: TEST_API,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(OrbitDailyVolumeQuery),
  });
  return res?.data?.orbitDailyStats;
};

const OrbitDailyRewardsQuery = `
  query orbitDailyRewards {
    dailyRewardSummaries(
      first: 1000
      orderBy: timestamp
      orderDirection: asc
      where: {type: "ORBIT"}
    ) {
      timestamp
      id
      amountDeposited
    }
  }
`;

export const OrbitDailyRewards = async () => {
  const client = new ApolloClient({
    uri: HUBURL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(OrbitDailyRewardsQuery),
  });
  return res?.data?.dailyRewardSummaries;
};

const RumbleDailyRewardsQuery = `
  query rumbleDailyRewards {
    dailyRewardSummaries(
      first: 1000
      orderBy: timestamp
      orderDirection: asc
      where: {type: "RUMBLE", payToken: "0xd4949664cd82660aae99bedc034a0dea8a0bd517"}
    ) {
      timestamp
      id
      amountDeposited
    }
  }
`;

export const RumbleDailyRewards = async () => {
  const client = new ApolloClient({
    uri: HUBURL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(RumbleDailyRewardsQuery),
  });
  return res?.data?.dailyRewardSummaries;
};

const TotalDailyRewardsQuery = `
  query totalDailyRewards {
    dailyRewardSummaries(
      first: 1000
      orderBy: timestamp
      orderDirection: asc
      where: {type: "ALL"}
    ) {
      timestamp
      id
      amountDeposited
    }
  }
`;

export const TotalDailyRewards = async () => {
  const client = new ApolloClient({
    uri: HUBURL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(TotalDailyRewardsQuery),
  });
  return res?.data?.dailyRewardSummaries;
};

const OrbitStatsQuery = `
  query totalDailyRewards {
    orbitSummaries(where: {payToken_in: ["0xd4949664cd82660aae99bedc034a0dea8a0bd517", "0x0000000000000000000000000000000000000000"]}) {
      auctionsCreated
      auctionsResolved
      bidsPlaced
      bundlesCreated
      bundlesDestroyed
      delistCount
      listingUpdates
      listingsPlaced
      nftCount
      numAddresses
      sellsVolume
      id
      transactionCount
      sellsCount
      offersCreated
      offersCancelled
      numOwners
    }
  }
`;

export const OrbitStats = async () => {
  const client = new ApolloClient({
    uri: TEST_API,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(OrbitStatsQuery),
  });
  return res?.data?.orbitSummaries;
};

const RumbleTopUsersByVolumeQuery = `
  query totalDailyRewards {
    userInfos(first: 10, orderBy: volume, orderDirection: desc) {
      volume
      id
    }
  }
`;

export const RumbleTopUsersByVolume = async () => {
  const client = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(RumbleTopUsersByVolumeQuery),
  });
  return res?.data?.userInfos;
};

const RumbleDailyVolumeQuery = `
  query dailyRumbleVolume {
    dailyRumbleSummaries(orderBy: timestamp, first: 1000, where: {payToken_in: ["0xd4949664cd82660aae99bedc034a0dea8a0bd517", "0x0000000000000000000000000000000000000000"]}) {
      id
      payToken
      volume
      timestamp
    }
  }
`;

export const RumbleDailyVolume = async () => {
  const client = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(RumbleDailyVolumeQuery),
  });
  return res?.data?.dailyRumbleSummaries;
};

const OAMintInfoQuery = `
  query dailyRumbleVolume($address: String) {
    mintInfo(id: $address) {
      amount
      id
      owner
    }
  }
`;

export const OAMintInfo = async (address) => {
  const client = new ApolloClient({
    uri: HUBURL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(OAMintInfoQuery),
    variables: {
      address,
    },
  });
  return res?.data?.mintInfo;
};

const UserOffersQuery = `
  query userOffers($offerFilter: Offer_filter, $collectionOfferFilter: CollectionOffer_filter) {
    offers(first: 5, where: $offerFilter, orderBy: pricePerItem, orderDirection: desc) {
      id
      creator
      nftAddress
      createdAt
      tokenId
      quantity
      payToken
      pricePerItem
      deadline
    }
    collectionOffers(
      first: 5
      where: $collectionOfferFilter
      orderBy: pricePerItem
      orderDirection: desc
    ) {
      id
      pricePerItem
      payToken
      deadline
    }
  }
`;

export const UserOffers = async (address, ownedNFTS) => {
  const client = new ApolloClient({
    uri: GRAPH_URL,
    cache: new InMemoryCache(),
  });

  const time = parseInt(new Date().getTime() / 1000);

  const res = await client.query({
    query: gql(UserOffersQuery),
    variables: {
      offerFilter: {
        creator_not: address,
        deadline_gt: time,
        nftAddress: "0x4c275ade386af17276834579b1a68146cef3c770",
        tokenId_in: ownedNFTS,
      },
      collectionOfferFilter: {
        creator_not: address,
        deadline_gt: time,
        nftAddress: "0x4c275ade386af17276834579b1a68146cef3c770",
      },
    },
  });
  return res?.data;
};
