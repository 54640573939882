import { ethers } from "ethers";
import { nFormatter } from "helpers/formatNumber";
import ApeProfile from "../../../assets/hub/apeProfile.svg";

export default   function DataHeader({ ownedOAs, rewards }) {

    const total = () => {
      let sum = 0
      rewards.forEach((r) => {
        if(r.type !== "ALL") {
          const _amount = parseFloat(ethers.utils.formatEther(r.amountDeposited ?? 0))
          sum += _amount
        }
      })
      return sum.toFixed(2)
    }

    return (
      <div className="flex flex-wrap gap-5">
        <div className="flex w-44 flex-col border  border-purple rounded-md pl-2 py-2.5 bg-dark bg-opacity-60">
          <span className="text-white text-lg font-medium">OAs Owned</span>
          <div className="flex items-center gap-2">
            <img className="w-5" alt="apeprofile" src={ApeProfile} />
            <span className="text-white font-semibold text-2xl">
              {ownedOAs.length}
            </span>
          </div>
        </div>
        <div className="flex w-44 flex-col border  border-purple rounded-md pl-2 py-2.5 bg-dark bg-opacity-60">
          <span className="text-white text-lg font-medium">
            Total Rewards
          </span>
          <span className="text-white font-semibold text-2xl">{nFormatter(total())} EVMOS</span>
        </div>
        <div className="flex w-44 flex-col border  border-purple rounded-md pl-2 py-2.5 bg-dark bg-opacity-60">
          <span className="text-white text-lg font-medium">
            Active Validators
          </span>
          <span className="text-white font-semibold text-2xl">7</span>
        </div>
        <div className="flex w-44 flex-col border  border-purple rounded-md pl-2 py-2.5 bg-dark bg-opacity-60">
          <span className="text-white text-lg font-medium">dApps</span>
          <span className="text-white font-semibold text-2xl">2</span>
        </div>
      </div>
    );
  }
