import cs from "classnames";

export const PROPOSAL_STATUS_VOTING_PERIOD = "PROPOSAL_STATUS_VOTING_PERIOD";
export const PROPOSAL_STATUS_PASSED = "PROPOSAL_STATUS_PASSED";
export const PROPOSAL_STATUS_REJECTED = "PROPOSAL_STATUS_REJECTED";
export const PROPOSAL_STATUS_DEPOSIT_PERIOD = "PROPOSAL_STATUS_DEPOSIT_PERIOD"
export function statusToLabel(proposal) {
  if (proposal.status === PROPOSAL_STATUS_VOTING_PERIOD) {
    return "Voting";
  } else if (proposal.status === PROPOSAL_STATUS_PASSED) {
    return "Passed";
  } else if (proposal.status === PROPOSAL_STATUS_REJECTED) {
    return "Rejected";
  } else if (proposal.status === PROPOSAL_STATUS_DEPOSIT_PERIOD) {
    return "Waiting Deposit"
  }
}

export function getProposalLabel(proposal ) {
  return (
    <div
      className={cs(
        "flex items-center px-2.5 py-0.5 gap-2 bg-opacity-30 rounded-full",
        {
          "bg-purple": proposal.status === PROPOSAL_STATUS_VOTING_PERIOD || proposal.status === PROPOSAL_STATUS_DEPOSIT_PERIOD,
          "bg-green": proposal.status === PROPOSAL_STATUS_PASSED,
          "bg-red": proposal.status === PROPOSAL_STATUS_REJECTED,
        }
      )}
    >
      <div
        className={cs(" rounded-full w-2 h-2 animate-pulse", {
          "bg-purple": proposal.status === PROPOSAL_STATUS_VOTING_PERIOD || proposal.status === PROPOSAL_STATUS_DEPOSIT_PERIOD,
          "bg-green": proposal.status === PROPOSAL_STATUS_PASSED,
          "bg-red": proposal.status === PROPOSAL_STATUS_REJECTED,
        })}
      />
      {statusToLabel(proposal)}
    </div>
  );
}
