import { ethers } from "ethers";
import { nFormatter } from "helpers/formatNumber";
import Orbit from "../../../assets/branding/Logo.svg";
import Rumble from "../../../assets/hub/Logo.svg";
import { Link } from "react-router-dom";

export default function ActiveDapps({ rewards }) {
  const orbitRewards = () => {
    const or = rewards.find((r) => r.type === "ORBIT");
    return nFormatter(ethers.utils.formatEther(or?.amountDeposited ?? 0), 2);
  };

  const rumbleRewards = () => {
    const or = rewards.find((r) => r.type === "RUMBLE");
    return nFormatter(ethers.utils.formatEther(or?.amountDeposited ?? 0), 2);
  };

  return (
    <div
      style={{ minWidth: "50%" }}
      className="mdd:w-6/12 w-full gap-3 flex flex-col"
    >
      <span className="text-white font-semibold text-2xl">OA dApps</span>
      <div className="rounded  flex-1 bg-dark border-2 border-darkGray pt-4 p-4 gap-6 flex flex-col shadow">
        <div className="flex gap-3 border rounded p-2 border-darkGray2 bg-purple bg-opacity-10 flex-col text-lg">
          <div className="flex items-center gap-3">
            <img alt="orbit" src={Orbit} className="rounded  h-8 w-8" />
            <span className="font-medium">Orbit Marketplace</span>
            <div className="rounded-full  animate-pulse bg-green h-2 w-2" />
          </div>
          <span className="text-base">
            Orbit Marketplace, an NFT marketplace home to all NFT collections in
            Evmos.
          </span>
          <div className="flex flex-col text-base justify-between flex-1 gap-2">
            <div className="flex gap-10">
              <div className="flex flex-col text-base">
                <span className="font-semibold">Rewards</span>
                <span> 20% fees to holders </span>
              </div>
              <div className="flex flex-col text-base">
                <span className="font-semibold">Total Rewards Accumulated</span>
                <span> {orbitRewards()} EVMOS </span>
              </div>
            </div>
            <Link
              to={{ pathname: "https://www.orbitmarket.io" }}
              target="_blank"
              className="flex justify-center"
            >
              <span className="text-sm text-purple">Learn more</span>
            </Link>
          </div>
        </div>
        <div className="flex gap-3 border rounded p-2 border-darkGray2 bg-purple bg-opacity-10 flex-col text-lg">
          <div className="flex items-center gap-3">
            <img alt="Rumble" src={Rumble} className="rounded  h-8 w-8" />
            <span className="font-medium">Rumble</span>
            <div className="rounded-full  animate-pulse bg-green h-2 w-2" />
          </div>
          <span className="text-base">
            Permissionless raffles, powered by Randomness Oracles in Evmos.
            Create & participate in fully provable and fair raffle to win ERC20
            tokens as as NFTS
          </span>
          <div className="flex flex-col text-base justify-between flex-1 gap-2">
            <div className="flex gap-10">
              <div className="flex flex-col text-base">
                <span className="font-semibold">Rewards</span>
                <span> 20% fees to holders </span>
              </div>
              <div className="flex flex-col text-base">
                <span className="font-semibold">Total Rewards Accumulated</span>
                <span> {rumbleRewards()} EVMOS </span>
              </div>
            </div>
            <Link
              to={{ pathname: "https://www.orbitrumble.com" }}
              target="_blank"
              className="flex justify-center"
            >
              <span className="text-sm text-purple">Learn more</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
