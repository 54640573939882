import {
  Legend,
  XAxis,
  YAxis,
  Tooltip as ChartTooltip,
  CartesianGrid,
  Area,
  Line,
  ComposedChart,
} from "recharts";
import ReactResizeDetector from "react-resize-detector";
import moment from "moment";
import { ethers } from "ethers";
import { ACRE_REWARDS, EVD_REWARDS, TORI_REWARDS } from "pages/Hub/stats/airdropRewards";

export default function TotalRewardsGraph({
  data = [],
  title,
  aggKey,
  height,
  legend,
}) {
  const sorted = [...data];
  let sum = 0;
  const volumeSum = sorted
    ?.sort((a, b) => parseInt(a?.timestamp) - parseInt(b?.timestamp))
    .map((d) => {
      sum += parseFloat(ethers.utils.formatEther(d[aggKey]));
      data = {
        date: moment(
          new Date(parseInt(d?.timestamp) * 1000).toISOString()
        ).format("D/M"),
      };

      data[aggKey] = parseFloat(sum.toFixed(2));

      if (parseInt(d.timestamp) < 1659589261) {
        data["TORI"] = 0;
        data["EVD"] = 0;
        data["ACRE"] = 0
      } else {
        data["TORI"] = TORI_REWARDS;
        data["EVD"] = EVD_REWARDS;
        data["ACRE"] = ACRE_REWARDS
      }
      data["Total"] = data.TORI + data[aggKey] + data.EVD + data.ACRE;

      return data;
    });


  return (
    <div className="flex w-full  rounded-lg  flex-col">
      <div className="text-white flex flex-col ">
        <ReactResizeDetector>
          {({ width }) =>
            width > 0 ? (
              <div
                style={{ height: height }}
                className="py-3   overflow-hidden relative"
              >
                <div className="absolute overflow-hidden">
                  <ComposedChart
                    syncId={"collection"}
                    width={width}
                    height={height}
                    data={volumeSum}
                    margin={{
                      top: 5,
                      right: 20,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <defs>
                      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop
                          offset="5%"
                          stopColor="#8884d8"
                          stopOpacity={0.8}
                        />
                        <stop
                          offset="95%"
                          stopColor="#8884d8"
                          stopOpacity={0}
                        />
                      </linearGradient>
                    </defs>
                    <XAxis dataKey="date" />
                    <YAxis />
                    {legend && <Legend />}
                    <ChartTooltip />

                    <CartesianGrid stroke="#343444" />

                    <Area
                      type="monotone"
                      dataKey="TORI"
                      stackId={"1"}
                      stroke="#2081E2"
                      fill="#2081E2"
                    />

                    <Area
                      type="monotone"
                      dataKey="EVD"
                      stackId={"1"}
                      stroke="#82ca9d"
                      fill="#82ca9d"
                    />

                    <Area
                      type="monotone"
                      dataKey="ACRE"
                      stackId={"1"}
                      stroke="#97BC42"
                      fill="#97BC42"
                    />

                    <Area
                      type="monotone"
                      dataKey={aggKey}
                      stroke="#8884d8"
                      stackId={"1"}
                      fillOpacity={1}
                      fill="url(#colorUv)"
                    />

                    <Line dataKey="Total" dot={null} stroke="#8454EB" />
                  </ComposedChart>
                </div>
              </div>
            ) : (
              <div>{width}</div>
            )
          }
        </ReactResizeDetector>
      </div>
    </div>
  );
}
