import { ethers } from "ethers";
import { UserOffers } from "graphql/api";
import { nFormatter } from "helpers/formatNumber";

import { useEVM } from "hooks/EVMhook";
import { useEffect, useState } from "react";
import ApeProfile from "../../../assets/hub/apeProfile.svg";
import { getDelegations } from "../helpers";
import OrbitOffers from "./OrbitOffers";
import PendingRewards from "./PendingRewards";
import Proposals from "./Proposals";

export default function Home({ ownedOAs }) {
  const { address } = useEVM();
  const [offers, setOffers] = useState([]);
  const [evmosBalance, setBalance] = useState(0);
  const [delegations, setDelegations] = useState([]);

  const stakedBalance = ethers.utils.formatEther(
    delegations?.find(
      (e) =>
        e.delegation.validator_address ===
        "evmosvaloper1mx9nqk5agvlsvt2yc8259nwztmxq7zjqep5khu"
    )?.balance?.amount ?? 0
  );

  useEffect(() => {
    if (!address) {
      return;
    }
    UserOffers(address, ownedOAs).then((uo) => {
      setOffers(uo);
    });
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    provider.getBalance(address).then((evmosBal) => {
      setBalance(parseFloat(evmosBal.toString()) / 10 ** 18);
    });
    getDelegations(address).then((d) => setDelegations(d));
  }, [address, ownedOAs]);

  function Header({ ownedOAs }) {
    return (
      <div className="flex flex-wrap gap-5">
        <div className="flex w-44 flex-col border border-purple rounded-md px-4 py-2.5 bg-purple bg-opacity-5">
          <span className="text-white">OA Count</span>
          <div className="flex items-center gap-2">
            <img className="w-4" alt="apeprofile" src={ApeProfile} />
            <span className="font-semibold text-white text-lg">
              {ownedOAs.length}
            </span>
          </div>
        </div>
        <div className="flex w-44 flex-col border border-purple rounded-md px-4 py-2.5 bg-purple bg-opacity-5">
          <span className="text-white">Evmos Balance</span>
          <span className="text-white font-semibold text-xl">
            {evmosBalance?.toFixed(3)} EVMOS
          </span>
        </div>
        <div className="flex w-44 flex-col border border-purple rounded-md px-4 py-2.5 bg-purple bg-opacity-5">
          <span className="text-white">Staked with OA</span>
          <span className="text-white font-semibold text-xl">{nFormatter(stakedBalance, 2)} EVMOS</span>
        </div>
      </div>
    );
  }

  return (
    <div className="flex gap-10 pt-8 px-4 flex-col w-full">
      <div className="flex gap-10 flex-1 flex-col">
        <span className="text-6xl uppercase text-purple font-bold">
          WELCOME BACK!
        </span>
        <Header ownedOAs={ownedOAs} />
        <div className="flex mt-10 gap-10 w-full">
          <PendingRewards address={address} />
          {(offers?.offers?.length > 0 ||
            offers?.collectionOffers?.length > 0) && (
            <OrbitOffers offers={offers} />
          )}
        </div>
        <Proposals />
      </div>
    </div>
  );
}
