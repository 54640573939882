import { ethers } from "ethers";
import { nFormatter } from "helpers/formatNumber";
import { useEVM } from "hooks/EVMhook";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function PendingRewards() {
  const [validatorRewards, setValRewards] = useState({});
  const [dappRewards, setDappRewards] = useState({});
  const { rewardsContract: rewardDistributorContract } = useEVM();

  useEffect(() => {
    async function fetchInfo() {
      const evmosRewards = await rewardDistributorContract?.instance
        ?.attach("0x4C4869C43112768024A6516CbCD328D9808ecDD7")
        ?.getRewardsBalances(ethers?.constants?.AddressZero);
      const toriRewards = await rewardDistributorContract?.instance
        ?.attach("0x4C4869C43112768024A6516CbCD328D9808ecDD7")
        ?.getRewardsBalances("0x205CF44075E77A3543abC690437F3b2819bc450a");
      const osmoRewards = await rewardDistributorContract?.instance
        ?.attach("0x4C4869C43112768024A6516CbCD328D9808ecDD7")
        ?.getRewardsBalances("0xfa3c22c069b9556a4b2f7ece1ee3b467909f4864");
      setValRewards({
        evmos: ethers.utils.formatEther(evmosRewards),
        tori: ethers.utils.formatUnits(toriRewards, 6),
        osmo: ethers.utils.formatUnits(osmoRewards, 6),
      });
    }
    async function getRewards() {
      const orbit = await rewardDistributorContract?.instance
        ?.attach("0xc790360740E00E381B98464109019B9B86B6dE2e")
        ?.getRewardsBalances("0xd4949664cd82660aae99bedc034a0dea8a0bd517");

      const rumble = await rewardDistributorContract?.instance
        ?.attach("0xB4FA35B3f04Cc6E56BFb8c0522Ed5e0935a85fbb")
        ?.getRewardsBalances("0xd4949664cd82660aae99bedc034a0dea8a0bd517");

      setDappRewards({
        orbit: ethers?.utils?.formatEther(orbit),
        rumble: ethers?.utils?.formatEther(rumble),
      });
    }
    if (rewardDistributorContract?.instance) {
      fetchInfo();
      getRewards();
    }
  }, [rewardDistributorContract?.instance]);

  return (
    <div className="flex flex-1">
      <div className="flex  w-full flex-col gap-3">
        <div className="text-white flex justify-between font-semibold text-2xl">
          Pending Rewards Rewards
          <Link to={"/rewards"}>
            <button className="rounded px-1.5 text-base border bg-purple bg-opacity-20 border-purple">
              Claim Rewards
            </button>
          </Link>
        </div>
        <div className="rounded text-white flex-1 bg-dark border-2 border-darkGray  p-4 gap-5 flex flex-col shadow">
          {(validatorRewards?.evmos ||
            validatorRewards?.tori ||
            validatorRewards?.osmo) && (
            <div className="flex gap-3 flex-1 w-full flex-col">
              <span className="font-semibold text-lg">Validator Rewards</span>
              {validatorRewards?.evmos && (
                <div className="flex justify-between">
                  <span>EVMOS</span>
                  <div className="border-b border-gray border-dotted flex-1 mx-2 mb-1" />

                  <span className="self-end text-right">
                    {nFormatter(validatorRewards?.evmos, 2)} EVMOS
                  </span>
                </div>
              )}
              {validatorRewards?.tori && (
                <div className="flex justify-between">
                  <span>TORI</span>
                  <div className="border-b border-gray border-dotted flex-1 mx-2 mb-1" />

                  <span className="self-end text-right">
                    {nFormatter(validatorRewards?.tori, 2)} TORI
                  </span>
                </div>
              )}
              {validatorRewards?.tori && (
                <div className="flex justify-between">
                  <span>OSMO</span>
                  <div className="border-b border-gray border-dotted flex-1 mx-2 mb-1" />

                  <span className="self-end text-right">
                    {nFormatter(validatorRewards?.osmo, 2)} OSMO
                  </span>
                </div>
              )}
            </div>
          )}
          {(dappRewards?.orbit || dappRewards?.rumble) && (
            <div className="flex gap-3 flex-1 w-full flex-col">
              <span className="font-semibold text-lg">dApp Rewards</span>
              {dappRewards?.orbit && (
                <div className="flex justify-between">
                  <span>Orbit</span>
                  <div className="border-b border-gray border-dotted flex-1 mx-2 mb-1" />

                  <span className="self-end text-right">
                    {nFormatter(dappRewards?.orbit, 2)} EVMOS
                  </span>
                </div>
              )}
              {dappRewards?.rumble && (
                <div className="flex justify-between">
                  <span>Rumble</span>
                  <div className="border-b border-gray border-dotted flex-1 mx-2 mb-1" />

                  <span className="self-end text-right">
                    {nFormatter(dappRewards?.rumble, 2)} EVMOS
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
