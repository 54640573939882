import cs from "classnames";
import { dismissToast, loadingToast, successToast } from "components/Toasts";
import { ethers } from "ethers";
import { handleError } from "helpers/errors";
import { useEVM } from "hooks/EVMhook";
import { useEffect, useState } from "react";

export default function Validator({ onClaim }) {
  const [loading, setLoading] = useState(false);

  async function handleClaim(contractAddress) {
    try {
      const transaction = await rewardDistributorContract?.instance
        ?.attach(contractAddress)
        ?.claimRewards("0xd4949664cd82660aae99bedc034a0dea8a0bd517");
      setLoading(true);
      const toastId = loadingToast("Claiming rewards...");
      rewardDistributorContract?.instance
        ?.attach(contractAddress)
        ?.provider?.waitForTransaction(transaction.hash)
        .then(() => {
          dismissToast(toastId);
          successToast("Rewards Claimed!");
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      handleError(error);
      setLoading(false);
    }
  }

  const { rewardsContract: rewardDistributorContract } = useEVM();
  const [userRewards, setUserRewards] = useState({});

  useEffect(() => {
    async function getRewards() {
      const orbit = await rewardDistributorContract?.instance
        ?.attach("0xc790360740E00E381B98464109019B9B86B6dE2e")
        ?.getRewardsBalances("0xd4949664cd82660aae99bedc034a0dea8a0bd517");

      const rumble = await rewardDistributorContract?.instance
        ?.attach("0xB4FA35B3f04Cc6E56BFb8c0522Ed5e0935a85fbb")
        ?.getRewardsBalances("0xd4949664cd82660aae99bedc034a0dea8a0bd517");

      setUserRewards({
        orbit: ethers?.utils?.formatEther(orbit),
        rumble: ethers?.utils?.formatEther(rumble),
      });
    }

    getRewards();
  }, [rewardDistributorContract?.instance]);

  return (
    <div className="flex flex-col gap-3" style={{ minWidth: "50%" }}>
      <span className="text-white font-semibold text-2xl">
        Claim dApps Rewards
      </span>
      <div className="rounded flex-1 bg-dark border-2 border-darkGray  p-4 gap-7 flex flex-col shadow">
        <div className="flex gap-5 flex-1 w-full flex-col">
          <div className="flex flex-col gap-1">
            <div className="text-lg font-medium">Orbit Marketplace</div>
            <div className="flex text-white flex-1 items-end justify-between">
              <span>My WEVMOS Rewards</span>
              <div className="border-b border-gray border-dotted flex-1 mx-2 mb-1" />
              <div className="flex gap-3">
                <span className="border h-7 rounded border-purple px-1.5">
                  {userRewards?.orbit}
                </span>

                <button
                  disabled={loading}
                  onClick={() =>
                    handleClaim("0xc790360740E00E381B98464109019B9B86B6dE2e")
                  }
                  className={cs(
                    "bg-purple h-7 rounded px-5  text-white font-medium",
                    {
                      "opacity-50": loading,
                    }
                  )}
                >
                  {loading === "evmos" ? "Loading..." : `Claim`}
                </button>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-1">
            <div className="text-lg font-medium">Rumble Raffles</div>
            <div className="flex text-white flex-1 items-end justify-between">
              <span>My WEVMOS Rewards</span>
              <div className="border-b border-gray border-dotted flex-1 mx-2 mb-1" />
              <div className="flex gap-3">
                <span className="border h-7 rounded border-purple px-1.5">
                  {userRewards?.rumble}
                </span>

                <button
                  disabled={loading}
                  onClick={() =>
                    handleClaim("0xB4FA35B3f04Cc6E56BFb8c0522Ed5e0935a85fbb")
                  }
                  className={cs(
                    "bg-purple h-7 rounded px-5  text-white font-medium",
                    {
                      "opacity-50": loading,
                    }
                  )}
                >
                  {loading === "evmos" ? "Loading..." : `Claim`}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
