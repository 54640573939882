import { useEffect, useState } from "react";
import IPFSGatewayTools from "@pinata/ipfs-gateway-tools/dist/browser";
import { fetchData } from "lambda/lambda";
import { useEVM } from "hooks/EVMhook";

const gatewayTools = new IPFSGatewayTools();
const IPFS_GATEWAY_2 = "https://nftstorage.link";
const IPFS_GATEWAY_4 = "https://cloudflare-ipfs.com";

const METADATA_URL = "https://cache.orbitmarket.io/metadata?";

let randomNumber = 0;

const GATEWAY_URLS = [IPFS_GATEWAY_2, IPFS_GATEWAY_4];

function getMetaURL() {
  randomNumber += 1;
  return GATEWAY_URLS[randomNumber % GATEWAY_URLS.length];
}

function checkIPFGateway(url) {
  let gateway = getMetaURL();
  if (!url?.includes("ipfs://")) return url;
  return gatewayTools.convertToDesiredGateway(url, gateway);
}

export function useTokenData(tokenId, contractAddress, isERC1155 = false) {
  const [data, setData] = useState({});

  useEffect(() => {
    async function _fetchData() {
      try {
        fetchData(
          METADATA_URL +
            `address=${contractAddress}&token=${tokenId}&type=${
              isERC1155 ? "1155" : "721"
            }`
        ).then(async (_tokenMetadata) => {
          const meta = await _tokenMetadata;
          if (meta) {
            meta["image"] = checkIPFGateway(meta?.image || meta?.image_url);
            setData(meta);
          }
        });
      } catch (error) {
        console.log("eee", error);
      }
    }
    if (
      contractAddress &&
      tokenId 
    ) {
      _fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { ...data };
}

export function useUnCachedTokenData(tokenId, contractAddress, isERC1155) {
  const [data, setData] = useState({});
  const { erc721Contract, erc1155Contract } = useEVM();

  function checkIPFGateway(url) {
    if (!url.includes("ipfs://")) return url;
    return gatewayTools.convertToDesiredGateway(url, getMetaURL);
  }

  useEffect(() => {
    try {
      if (tokenId && isERC1155 !== undefined) {
        if (isERC1155) {
          erc1155Contract?.instance
            ?.attach("0xa05e4ae95fB9b32Fcd80fFd6c275dB8b0842883b")
            ?.uri(tokenId)
            .then((_tokenURL) => {
              const tokenURL = checkIPFGateway(_tokenURL);
              fetch(tokenURL).then(async (_tokenMetadata) => {
                const meta = await _tokenMetadata.json();
                meta["image"] = checkIPFGateway(meta["image"]);
                setData(meta);
              });
            });
        } else {
          erc721Contract?.instance
            ?.attach(contractAddress)
            .tokenURI(tokenId)
            .then((_tokenURL) => {
              let tokenURL = checkIPFGateway(_tokenURL);
              fetch(tokenURL).then(async (_tokenMetadata) => {
                const meta = await _tokenMetadata.json();
                let tokenMetadata = meta;
                let imageURL = checkIPFGateway(
                  tokenMetadata?.image || tokenMetadata?.image_url
                );
                tokenMetadata["image"] = imageURL;
                setData(tokenMetadata);
              });
            });
        }
      }
    } catch (error) {
      console.log("eee", error);
    }
  }, [
    contractAddress,
    erc1155Contract?.instance,
    erc721Contract?.instance,
    isERC1155,
    tokenId,
  ]);

  return { ...data };
}

export function getTokenName(data) {
  if (data?.bundle?.name && data?.bundle?.name !== "")
    return data?.bundle?.name;
  if (data?.name)
    return data.name.substring(0, 30).replace(/[\u200B-\u200D\uFEFF]/g, "_");
  else if (data.tokenId) return formatToken(data.tokenId);
  return ``;
}

export function useBundleTokensData(tokens, contractAddress) {
  const [data, setData] = useState([]);
  useEffect(() => {
    async function _fetchData() {
      const _data = [];
      for (let tokenId of tokens) {
        try {
          const _tokenMetadata = await fetchData(
            METADATA_URL + `address=${contractAddress}&token=${tokenId}`,
            0
          );
          const meta = _tokenMetadata;
          let tokenMetadata = meta;
          let imageURL = checkIPFGateway(
            tokenMetadata?.image || tokenMetadata?.image_url
          );
          tokenMetadata["image"] = imageURL;
          _data.push(tokenMetadata);
        } catch (error) {
          _data.push({ image: null, attributes: [] });
          console.log("eee", error);
        }
      }
      setData(_data);
    }

    if (tokens && contractAddress) {
      _fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data;
}

export function formatToken(tokenId) {
  let length = tokenId?.toString().length;
  if (length > 10) {
    return `#${tokenId?.toString().substring(0, 4)}...${tokenId
      ?.toString()
      .substring(length - 4, length)}`;
  } else {
    return `#${tokenId?.toString()}`;
  }
}
