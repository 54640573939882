import detectEthereumProvider from "@metamask/detect-provider";
import { isProd } from "helpers/env";
import { useContext, useEffect, useState } from "react";

// Import auto generated context from Symphony file
import {
  OrbitContext as MainOrbitContext,
  ERC721Context as MainERC721Context,
  ERC20Context as MainERC20Context,
  OrbitRewardsDistributorContext as MainOrbitRewardsDistributorContext,
  WEVMOSContext as MainWEVMOSContext,
  ERC1155Context as MainERC1155Context,
  OrbitAuctionContext as MainOrbitAuctionContext,
} from "../hardhat/MainSymfoniContext";

import {
  OrbitV2Context as TestOrbitContext,
  ERC721Context as TestERC721Context,
  ERC20Context as TestERC20Context,
  OrbitRewardsDistributorContext as TestOrbitRewardsDistributorContext,
  WEVMOSContext as TestWEVMOSContext,
  ERC1155Context as TestERC1155Context,
  OrbitBundlesContext as TestOrbitBundlesContext,
  OrbitAuctionV2Context as TestOrbitAuctionContext,
} from "../hardhat/SymfoniContext";

import { chain } from "./chains";

const OrbitContext = isProd ? MainOrbitContext : TestOrbitContext;
const ERC721Context = isProd ? MainERC721Context : TestERC721Context;
const ERC20Context = isProd ? MainERC20Context : TestERC20Context;
const OrbitRewardsDistributorContext = isProd
  ? MainOrbitRewardsDistributorContext
  : TestOrbitRewardsDistributorContext;
const WEVMOSContext = isProd ? MainWEVMOSContext : TestWEVMOSContext;
const ERC1155Context = isProd ? MainERC1155Context : TestERC1155Context;
const OrbitBundlesContext = TestOrbitBundlesContext; // TODO NOCOMMIT confitional assignment using isProd from prod or testnet contract
const OrbitAuctionContext = isProd
  ? MainOrbitAuctionContext
  : TestOrbitAuctionContext;

export function useEVM() {
  const CHAINID = chain.chainId;
  const erc721Contract = useContext(ERC721Context);
  const orbitContract = useContext(OrbitContext);
  const erc20Contract = useContext(ERC20Context);
  const erc1155Contract = useContext(ERC1155Context);
  const rewardsContract = useContext(OrbitRewardsDistributorContext);
  const wrapperContract = useContext(WEVMOSContext);
  const orbitBundlesContract = useContext(OrbitBundlesContext);
  const auctionContract = useContext(OrbitAuctionContext);

  const [isNetwork, setIsNetwork] = useState(false);
  const [address, setAddress] = useState(null);
  const [isMetamask, setIsMetamask] = useState(false);

  const CHAIN_PARAMS = chain;

  async function addNetwork() {
    if (isMetamask && isNetwork) {
      return connectWallet();
    }
    const provider = await detectEthereumProvider();
    if (!provider) return;
    await provider.request({
      method: "wallet_addEthereumChain",
      params: [CHAIN_PARAMS],
    });
  }

  async function connectWallet() {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAddress(accounts[0]);
      } catch (error) {
        if (error.code === 4001) {
          // User rejected request
        }
      }
    }
  }

  useEffect(() => {
    const fetcher = async () => {
      const provider = await detectEthereumProvider();
      setIsNetwork(
        provider?.chainId?.toString().toLowerCase() ===
          CHAINID.toString().toLowerCase()
      );
      setAddress(provider?.selectedAddress);

      setIsMetamask(provider?.isMetaMask);
    };
    fetcher();
  }, [CHAINID, orbitContract?.instance]);


  return {
    isConnected: address && isNetwork,
    isNetwork,
    erc721Contract,
    orbitContract,
    isMetamask,
    addNetwork,
    address: isNetwork ? address : null,
    connectWallet,
    erc20Contract,
    rewardsContract,
    wrapperContract,
    erc1155Contract,
    orbitBundlesContract,
    auctionContract,
  };
}
