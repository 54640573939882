import {
    generateEndpointGetDelegations,

  } from "@tharsis/provider";
  import { ethToEvmos } from "@tharsis/address-converter";


  export async function getDelegations(address) {
    const NODE_URL = "https://rest.bd.evmos.org:1317"
  
    console.log("debug-address: ",ethToEvmos(address.toLocaleLowerCase()) , address)
    try {
      const resp = await fetch(
        `${NODE_URL}${generateEndpointGetDelegations(ethToEvmos(address.toLocaleLowerCase()))}`,
        
      );
      const value = await resp.json();
      if (value.code) {
        return [];
      }
      const parsed = value;
      return parsed.delegation_responses;
    } catch (e) {
      console.error(e);
      return [];
    }
  }