import { configureStore } from "@reduxjs/toolkit";
import dataRefreshReducer from "redux/slices/dataRefreshSlice";
import fourOhFourReducer from "redux/slices/fourOhFourSlice";

export default configureStore({
  reducer: {
    dataRefresher: dataRefreshReducer,
    fourOhFour: fourOhFourReducer,
  },
});
