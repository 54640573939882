/* eslint-disable react-hooks/exhaustive-deps */

import { SymfoniContext } from "helpers/env";
import User from "../assets/icons/contact.svg";
import cs from "classnames";
import { useEffect, useContext, useRef, useState } from "react";
import { chain } from "../hooks/chains";
import { Link } from "react-router-dom";

import { useEVM } from "hooks/EVMhook";
import { UserTokens } from "graphql/api";
import { useLocation } from "react-router-dom";
import SideMenu from "./SideMenu";
import TopBar from "./TopBar";
import MobileSideMenu from "./MobileSideMenu";
import Address from "components/Address";

const CHAINID = chain.chainId;

const _getChainID = (resolve) => {
  if (window.ethereum.chainId != null) {
    resolve(window.ethereum.chainId);
  } else {
    setTimeout(() => _getChainID(resolve), 100);
  }
};
const getChainID = () => new Promise(_getChainID);

// function WrongNetwork() {
//   return (
//     <div className="flex z-40 flex-col  sticky top-0">
//       <div
//         onClick={() => {
//           return window.open(
//             "https://evmos.dev/guides/keys-wallets/metamask.html",
//             "_blank"
//           );
//         }}
//         className="flex-1 px-1 bg-purple flex justify-center text-white h-6 font-semibold text-center text-sm cursor-pointer"
//       >
//         <span className="hidden text-center  mdd:flex">
//           The data shown is from Evmos mainnet network. You are currently not
//           connected to Evmos mainnet. To use Orbit install Metamask and connect
//           to Evmos mainnet.
//         </span>
//         <span className="flex text-center mdd:hidden">
//           To use Orbit install Metamask and connect to Evmos mainnet.
//         </span>
//       </div>
//     </div>
//   );
// }

function OwnerGate() {
  const { address, isMetamask, isConnected, isNetwork, addNetwork } = useEVM();

  function getButton() {
    if (!isMetamask) return "Install Metamask";
    if (isConnected && isNetwork)
      return <Address address={address} length={11} />;
    else if (isNetwork && !isConnected) return "Connect Wallet";
    else return "Switch to EVMOS";
  }

  return (
    <div className="pt-16 px-16">
      <div className="flex bg-purple gap-8 p-6 bg-opacity-5 border h-96 border-purple items-center rounded-xl flex-col">
        <div className="text-3xl flex gap-1.5 md:flex-row flex-col  md:items-end self-center">
          <span className="text-white">OA Hub is</span>
          <span className="text-purple text-4xl font-medium">exclusive</span>
          <span className="text-4xl text-white font-semibold">
            to OA Holders
          </span>
        </div>
        <div className="flex flex-col gap-5">
          <span className="text-white text-xl">
            Already a holder? Connect your wallet
          </span>
          <div className="flex justify-center">
            {(!isConnected || !isNetwork) && (
              <div
                onClick={() => {
                  return isMetamask
                    ? addNetwork()
                    : window.open(
                        "https://evmos.dev/guides/keys-wallets/metamask.html",
                        "_blank"
                      );
                }}
                className={cs(
                  "flex cursor-pointer items-center gap-2 p-1 mdd:pr-2.5 rounded-full border border-purple"
                )}
              >
                <img
                  className="w-7 h-7 p-1 rounded-full border border-purple"
                  alt="User"
                  src={User}
                />
                <span className="text-white text-sm">{getButton()}</span>
              </div>
            )}
          </div>
        </div>
        <div className="mt-10">
          <span className="text-white text-xl">
            Buy Orbital Apes in{" "}
            <Link
              to={{ pathname: "https://www.orbitmarket.io" }}
              target="_blank"
              className="text-purple"
            >
              {" "}
              Orbit Market
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
}

function BaseLayout({ children, location }) {
  const symphony = useContext(SymfoniContext);
  const init = useRef();
  const [ownedOAs, setOwnedOAs] = useState([]);

  const { address } = useEVM();
  const firstUpdate = useRef(true);

  const usePathname = () => {
    const location = useLocation();
    return location.pathname;
  };

  useEffect(() => {
    if (window.ethereum && !init.current) {
      setTimeout(async () => {
        const chainId = await getChainID();
        if (chainId === CHAINID.toString()) {
          init.current = true;
          symphony.init("web3modal");
        } else {
          init.current = true;
          symphony.init("hardhat");
        }
      }, 250);
    } else if (!init.current) {
      init.current = true;
      symphony.init("hardhat");
    }
  }, []);

  useEffect(() => {
    if (firstUpdate.current === false || !address) {
      return;
    }
    firstUpdate.current = false;

    UserTokens(address).then((ownedCount) => {
      setOwnedOAs(ownedCount?.map((o) => o.tokenId));
    });
  }, [address]);

  return (
    <div
      style={{
        minHeight: "100vh",
      }}
      className="flex bg-dimPurple relative flex-col"
    >
      <div className="flex h-full relative">
        <SideMenu address={address} path={usePathname()?.toLocaleLowerCase()} />
        <MobileSideMenu />
        <div className="flex h-full flex-1 flex-col">
          <TopBar ownedOAs={ownedOAs} />
          <main
            style={{ minHeight: "100vh" }}
            className="flex bg-dark pt-16 flex-1 flex-col "
          >
            {ownedOAs.length > 0 && {
              ...children,
              props: { ownedOAs: ownedOAs },
            }}
            {ownedOAs.length <= 0 && <OwnerGate />}
          </main>
        </div>
      </div>
    </div>
  );
}

export default BaseLayout;
