import WebBanner from "../../../assets/bgs/WebBanner.png";
import Validator from "../../../components/hub/ValidatorBox";
import StakeBox from "../../../components/hub/StakeBox";

import ActiveValidators from "./ActiveValidators";
import ActiveDapps from "./ActiveDapps";
import DataHeader from "./DataHeader";
import { useEffect, useState } from "react";
import { RewardsSummary } from "graphql/api";
import { ethers } from "ethers";
import { useEVM } from "hooks/EVMhook";
import { dismissToast, loadingToast, successToast } from "components/Toasts";
import { handleError } from "helpers/errors";

export default function Landing({ ownedOAs }) {

  const [rewards, setRewards] = useState([])
  const [loading, setLoading] = useState(false)

  const {rewardsContract} = useEVM()

  async function claimRewards(network) {
    const networkAddress = {
      evmos: ethers.constants.AddressZero,
      tori: "0x205CF44075E77A3543abC690437F3b2819bc450a",
      osmo: "0xfa3c22c069b9556a4b2f7ece1ee3b467909f4864"
    }

    setLoading(network);
    try {
      const _rewardAddress = networkAddress[network]
      const transaction = await rewardsContract?.instance
        ?.attach("0x4C4869C43112768024A6516CbCD328D9808ecDD7")
        ?.claimRewards(_rewardAddress);

      const toastId = loadingToast(`Claiming...`);
      rewardsContract?.instance
        ?.attach("0x4C4869C43112768024A6516CbCD328D9808ecDD7")
        ?.provider?.waitForTransaction(transaction.hash)
        .then(async () => {
          dismissToast(toastId);
          successToast("Purchase complete!", {
            duration: 4000,
          });
          setLoading(false);
        });
    } catch (error) {
      handleError(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    RewardsSummary().then(rs => {
      setRewards(rs)
    })
  }, [])

  return (
    <div className="text-white pb-24 w-full  bg-dark flex flex-col gap-12 items-center">
      <div
        style={{ backgroundImage: `url(${WebBanner})` }}
        className="flex w-full mainbg flex-col"
      >
        <div className="gap-2 flex items-center flex-col bg-gradient-to-b from-dark to-dark via-transparent w-full">
          <div className="gap-2 px-6 pb-10 pt-8 flex  flex-col bg-dark bg-opacity-70 w-full">
            <div className="flex flex-col flex-1 pb-10 md:items-start items-start gap-16 justify-between">
              <div className="flex gap-4 h-96 flex-1 flex-col">
                <span className="text-6xl uppercase text-purple font-bold">
                  OA REWARDS
                </span>
                <div className="flex flex-col gap-8">
                  <span className="font-semibold text-white text-3xl">
                    Claim rewards and access exclusive perks
                  </span>
                </div>
              </div>
              <DataHeader rewards={rewards} ownedOAs={ownedOAs} />
              {/* <div>

              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="flex px-6 gap-4 w-full">
        <div className="flex flex-1 mdd:flex-row justify-between flex-col gap-4 ">
          <Validator
            userRewards={{ evmos: 0, tori: 0, osmo: 0 }}
            orbitalBalance={0}
            onClaim={claimRewards}
            loading={loading}
          />
          <StakeBox orbitalBalance={0} />
        </div>
      </div>

      <div className="flex px-6 gap-8 flex-col w-full ">
        <div className="flex flex-col gap-2">
          <span className="text-purple  font-semibold text-2xl">
            How are rewards earned?
          </span>
          <span>
            Orbital Apes owners earn rewards just by holding OA NFTs.
            <br /> Rewards are accumulated from different sources across the
            Orbital Apes ecosystem.
            <br /> Currently the two main reward sources are Validator rewards
            and dApps rewards
          </span>
          <div className="flex gap-12">
            <div className="flex items-center gap-2">
              <div className="rounded-full justify-self-end animate-pulse bg-green h-2 w-2" />
              Rewards being distributed
            </div>
            <div className="flex items-center gap-2">
              <div className="rounded-full justify-self-end animate-pulse bg-yellow h-2 w-2" />
              Rewards accumulating
            </div>
            <div className="flex items-center gap-2">
              <div className="rounded-full justify-self-end animate-pulse bg-red h-2 w-2" />
              Inactive
            </div>
          </div>
        </div>

        <div className="flex flex-1 mdd:flex-row justify-between flex-col gap-4 ">
          <ActiveValidators />
          <ActiveDapps rewards={rewards} />
        </div>
      </div>
    </div>
  );
}
