function Hamburger({ onClick }) {
  return (
    <div 
    style={{ top: 0, left: 20 }}

    className="bg-dark pt-5 fixed w-full md:hidden flex h-16">
    <button
      onClick={() => onClick(true)}
      className="md:hidden flex  pr-5 flex-col "
    >
      <span className="bg-purple rounded" style={{ width: 22, height: 2 }} />
      <span
        className="bg-purple rounded"
        style={{ width: 22, height: 2, marginBottom: 6, marginTop: 6 }}
      />
      <span className="bg-purple rounded" style={{ width: 22, height: 2 }} />
    </button>
    </div>
  );
}

export default Hamburger;
