import OrbitStats from "./OrbitStats";
import OrbitalApesStats from "./OrbitalApesStats";
import RumbleStats from "./RumbleStats";
import { useEffect, useState } from "react";
import { RewardsSummary } from "graphql/api";

export default function Landing() {

  const [totalRewards, setTotalRewards] = useState([])

  useEffect(() => {
    RewardsSummary().then(rs => {
      setTotalRewards(rs)
    })
  }, [])


  return (
    <div className="flex gap-10 pb-16 pt-8 px-6 flex-col ">
      <div className="flex gap-4 flex-col">
        <span className="text-6xl uppercase text-purple font-bold">
          OA ECOSYSTEM STATS
        </span>
        <div className="flex flex-col gap-8">
          <span className="font-semibold text-white text-3xl">
            Explore metrics
          </span>
        </div>
      </div>
      <OrbitalApesStats totalRewards={totalRewards} />
      <OrbitStats totalRewards={totalRewards} />
      <RumbleStats totalRewards={totalRewards} />
    </div>
  );
}
