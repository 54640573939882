import ApeProfile from "../../../assets/hub/apeProfile.svg";
import { nFormatter } from "helpers/formatNumber";
import { formatEther } from "helpers/payTokens";
import moment from "moment";
import { Link } from "react-router-dom";

export default function OrbitOffers({ offers }) {
  return (
    <div className="flex flex-1">
      <div className="flex  w-full flex-col gap-3">
        <div className="text-white flex justify-between font-semibold text-2xl">
          Your OA Offers
          <Link
          to={{ pathname: "https://www.orbitmarket.io" }}
          target="_blank">
          <button className="rounded px-1.5 text-base border bg-purple bg-opacity-20 border-purple">
            See in Orbit
          </button>
          </Link>
        </div>
        <div className="rounded text-white flex-1 bg-dark border-2 border-darkGray  p-4 gap-5 flex flex-col shadow">
          <div>
            <span className="font-semibold text-lg">Collection Offers</span>
          </div>
          {offers?.collectionOffers?.length > 0 && (
            <div className="flex gap-3 flex-col">
              {offers?.collectionOffers?.map((co, index) => {
                return (
                  <div
                    key={co.id}
                    className="flex justify-between border rounded border-medium p-2"
                  >
                    <div className="flex gap-3">
                      <img
                        src={ApeProfile}
                        alt="collectionOffer"
                        className="w-6 p-1 border rounded h-6"
                      />
                      <span>
                        {nFormatter(formatEther(co?.pricePerItem))} EVMOS
                      </span>
                    </div>

                    <span>
                      Expires:
                      {` ${moment(
                        new Date(co?.deadline * 1000).toISOString()
                      ).format("MMMM Do YYYY, h:mm a")}`}
                    </span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
