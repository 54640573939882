import { getName } from "helpers/address";
import { useEffect, useState } from "react";

export default function Address({ address, length, ellipsis }) {
  const [name, setName] = useState(null);
  const [loading, setLoading] = useState(true);

  function formatAddress() {
    return ellipsis
      ? `${address?.substring(0, length)}...${address?.substring(
          42 - length,
          42
        )}`
      : address?.substring(0, length);
  }

  useEffect(() => {
    (async () => {
      if (address && length) {
        const _name = await getName(address, length);
        setName(_name);
      }
      setLoading(false);
    })();
  }, [address, length]);

  if (loading) {
    return null;
  }
  return <>{`${name ? name : formatAddress()}`}</>;
}
